import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import ls from './ls';
import { SystemContext } from './context';

var timerId

const AxiosComponent = () => {
  const systemState = useContext(SystemContext)
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const userTheme = localStorage.getItem('theme')

  const initialTheme = () => {
    // if(userTheme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)){
    //   document.documentElement.classList.add('dark')
    // }
    // else{
    //   localStorage.setItem('theme', 'light')
    //   document.documentElement.classList.remove('dark')
    // }
  }

  const handleTheme = () => {
    // if(systemState.state.theme === 'dark'){
    //   document.documentElement.classList.add('dark')
    // }
    // else{
    //   document.documentElement.classList.remove('dark')
    // }
  }

  useEffect(() => {
    handleTheme();
  },[systemState.state.theme])


  useEffect(() => {
    initialTheme();
    axios.interceptors.request.use(
      async config => {
        config.timeout = 10000
        if(ls.get('userToken')){
          var token = ls.get('userToken')
        }
        else {
          token = null
        }
        if (token) {
          config.headers.Authorization = "Bearer " + token;
        }
        return config
      }
    );

    axios.interceptors.response.use(
      response => {
        if(response.data.message) {
          systemState.action.onSuccess(response.data.message)
        }
        else if(response.config.method === 'delete') {
          systemState.action.onSuccess('Амжилттай устгалаа!')
        }
        else if(response.config.method === 'post') {          
          if(response.config.url == 'public/login'){
            systemState.action.onSuccess('Амжилттай нэвтэрлээ!')          
          }
          else if(response.config.url == 'public/invoice/course'){
          }
          else{
            systemState.action.onSuccess('Үйлдэл амжилттай!')          
          }
        }
        else if(response.config.method === 'put') {
          systemState.action.onSuccess('Үйлдэл амжилттай!')
        }
        return response
      },
      error => {
        if(error.code === 'ECONNABORTED') {
          systemState.action.onNotConnect()
        }
        if(error.response.status == 403){
          systemState.action.unauthorized()
        }
        if(error.response.status == 409){
          systemState.action.handleConflictModal(true, error.response.data)
        }
        if(error.response) {
          if(error.response.data){
            if(error.response.data.value) {
              if(typeof(error.response.data.value) == 'object') {
                systemState.action.onError(error.response.data.value[0].msg)
              }
              else {
                systemState.action.onError(error.response.data.value)
              }
            }
            else {
              systemState.action.onError('Үйлдэл амжилтгүй боллоо!')
            }
          }
        }
        return Promise.reject(error.response)
      }
    )
  }, [])

  return null
}

export default AxiosComponent
