import React, { useEffect, useState } from 'react';
import { Formik, Field, Form} from 'formik';
import moment from 'moment'; 
// import { Editor } from '@tinymce/tinymce-react';
import CropImageField from '../CropImageField';


const CustomForm = ({onSubmit, fields, closeModal, editData, editable, loading, col}) => {
  const [ colWidth, setColWidth ] = useState(24)
  const [ showPassword, setShowPassword ] = useState(false)
  useEffect(() => {
    switch(col){
      case 2: 
        return setColWidth(12);
      case 3: 
        return setColWidth(8);
      default: return setColWidth(24)
    }
  },[col])
  
  const getInitValue = () => {
    let tmp = {}
    fields.map((item, i) => {
      if(editData) {
        if(item.type == 'date') {
          tmp[item.dataIndex] = editData[item.dataIndex] != null ? moment(editData[item.dataIndex], 'YYYY-MM-DD HH:mm:ss') : moment()
        }
        else if(item.type == 'time') {
          tmp[item.dataIndex] = editData[item.dataIndex] != null ? moment(editData[item.dataIndex], 'HH:mm:ss') : null
        }
        else if(item.type == 'dropdown') {
          tmp[item.dataIndex] = editData[item.dataIndex]
        }
        else {
          tmp[item.dataIndex] = editData[item.dataIndex]
        }
      }
      else {
        item.type == 'orderIndex' ?   
        tmp[item.dataIndex] = item.defaultIndex
        :
        tmp[item.dataIndex] = item.value;
      }
    })
    return tmp
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getInitValue()}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values, resetForm)         
      }}>
      {
        ({
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
          handleChange,
          setFieldValue,
          resetForm
        }) => {
          return (
            <Form className='text-left'>
              <div className='grid gap-2 text-sm' gutter={col ? 12 : 0}>                
                {
                  fields.sort(function(a,b){ return a.formOrder - b.formOrder; }).map((item, i) => {
                    if(item.renderOnForm != false)
                    switch (item.type){
                      case 'dropdown':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth} >
                            <div className='w-full mb-3'>
                              <span className='inline-block mb-2'>
                                {`${item.title} : `}
                              </span>
                              {item.warning && 
                                <div style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              alignItems: 'center',
                                              marginBottom: '10px',
                                              backgroundColor:'#ffefbc', 
                                              color: '#c69843', 
                                              border: '1px solid #fbdd87', 
                                              borderRadius: '5px', 
                                              padding: '2px 5px',
                                              fontStyle: 'italic'}}> 
                                  {/* <CIcon style={{margin: '0 10px'}} 
                                          content={freeSet.cilWarning}
                                          size='xl' /> */}
                                  {item.warning} 
                                </div>}
                              <select
                                style={{flex: 1, width: '100%'}}
                                defaultValue={values[item.dataIndex]}
                                value={values[item.dataIndex]}
                                mode={item.multiple ? 'multiple' : 'default'}
                                placeholder={`${item.title} сонгох`}
                                allowClear={item.clearable ? item.clearable : false}
                                onChange={(value) => { 
                                  item.changeCallback &&                            
                                  item.changeCallback(value)
                                  setFieldValue(item.dataIndex, value)
                                }}>
                                {
                                  item.options.map((option, optionIndex) => {
                                    return (
                                      <option key={optionIndex} value={option.value}>{option.label}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        )                      
                      case 'check':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                              <div className="flex items-center mb-3">
                                <input 
                                  id="checkbox-2" 
                                  type="checkbox" 
                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
                                  checked={values[item.dataIndex]}
                                  onChange={(e) => {
                                    setFieldValue(item.dataIndex, !values[item.dataIndex])
                                  }}
                                />
                                <label for="checkbox-2" className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{`${item.title} : `}</label>
                              </div>                              
                          </div>
                        )
                      case 'textArea':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                            <div className='w-full mb-2'>
                              <span className='inline-block mb-1'>
                                {`${item.title} : `}
                              </span>
                              <div className='w-full'>
                                <Field
                                  disabled={item.fieldDisabled}
                                  name={item.dataIndex}
                                  type={item.type}
                                  rows={5}
                                  className='p-2.5 w-full text-sm focus:bg-white bg-gray-50 border focus:outline-none border-gray-300  dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
                                  placeholder={`${item.title} оруулах`}
                                  onChange={(e) => setFieldValue(item.dataIndex, e.target.value)}
                                  as={'textarea'}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      // case 'textEditor':
                      //   return (
                      //     <div key={i} xs={item.offset ? item.offset : colWidth}>
                      //       <div className='w-full mb-3'>
                      //         <span className='inline-block mb-2'>
                      //           {`${item.title} : `}
                      //         </span>
                      //         <div className='w-full mb-3-field'>
                      //           <Editor
                      //             apiKey='a22ry67l2lvwvfa9lvrr2yeeih0vpyw7brx1cc0g2lbm41rx'
                      //             value={values[item.dataIndex]}
                      //             init={{         
                      //               height:'300px',
                      //               width: '100%',               
                      //               plugins: [
                      //                 'advlist autolink lists link image', 
                      //                 'charmap print preview anchor help',
                      //                 'searchreplace visualblocks code',
                      //                 'insertdatetime media table paste wordcount'
                      //               ],
                      //               toolbar:
                      //                 'undo redo | formatselect | bold italic | \
                      //                 alignleft aligncenter alignright | \
                      //                 bullist numlist outdent indent | help',
                      //               block_formats: 'Paragraph=p;Header 1=h2;Header 2=h3;',
                      //               style_formats: [
                                    
                      //               ]
                      //             }}                        
                      //             onEditorChange={(e, editor) => {
                      //               setFieldValue(item.dataIndex, e)
                      //             }}
                      //           />
                      //         </div>
                      //       </div>
                      //     </div>
                      //   )
                      case 'file':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                            <div className='w-full mb-2'>
                              <span className='inline-block mb-1'>
                                {`${item.title} : `}
                              </span>
                              <div className='w-full'>
                                <input
                                  style={{overflow: 'hidden'}}
                                  name={item.dataIndex}
                                  type='file'
                                  placeholder={`${item.title} оруулах`}
                                  onChange={e => setFieldValue('file', e.currentTarget.files[0])}>
                                </input>
                              </div>
                            </div>
                          </div>
                        )
                      case 'cropImg':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                            <div className='w-full mb-2'>
                              <span className='inline-block mb-1'>
                                {`${item.title} : `}
                              </span>
                              <div className='w-full'>
                                <CropImageField
                                  onChange={(e) => setFieldValue(item.dataIndex, e)}
                                  aspect={item.aspect}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      case 'orderIndex':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                            <div className='w-full mb-2'>
                              <span className='inline-block mb-1'>
                                {`${item.title} : `}
                              </span>
                              <div className='w-full'>
                                <Field
                                  disabled={item.fieldDisabled}
                                  defaultValue={item.defaultIndex}
                                  name={item.dataIndex}
                                  type='number'
                                  placeholder={`${item.title} оруулах`}
                                  value={values[item.dataIndex]}
                                >
                                </Field>
                              </div>
                            </div>
                          </div>
                        )
                      case 'number':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                            <div className='w-full mb-2'>
                              <span className='inline-block mb-1'>
                                {`${item.title} : `}
                              </span>
                              <div className='w-full'>
                                <Field
                                  disabled={item.fieldDisabled}
                                  name={item.dataIndex}
                                  type={item.type}
                                  max={item.max}
                                  min={item.min}
                                  placeholder={`${item.title} оруулах`}
                                  value={values[item.dataIndex]}
                                >
                                </Field>
                              </div>
                            </div>
                          </div>
                        )
                      case 'price':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                            <div className='w-full mb-2'>
                              <span className='inline-block mb-1'>
                                {`${item.title} : `}
                              </span>
                                <div className='w-full'>
                                  <input
                                    style={{width:'100%'}}
                                    value={values[item.dataIndex]}
                                    name={item.dataIndex}
                                    type='number'
                                    formatter={value => `${new Intl.NumberFormat().format(value)}`}
                                    onChange={(e) => {
                                      setFieldValue(item.dataIndex, e)
                                    }}
                                  />
                                </div>                    
                            </div>
                          </div>
                        )                     
                      case 'view':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                            <div className='w-full mb-2'>
                              <span className='inline-block mb-1'>
                                {`${item.title} : `}
                              </span> 
                              <div className='w-full'>
                                <div style={{flex: 1, width: '100%', minHeight:'32px',  border: '1px solid #c2c8d1', padding: '4px 7px'}}>
                                    { values[item.dataIndex] }
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      case 'password':
                        return (
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                            <div className='w-full mb-2'>  
                              <span className='inline-block mb-1'>
                                {`${item.title} : `}
                              </span> 
                              <div className="w-full flex relative">
                                <Field
                                  disabled={item.fieldDisabled}
                                  name={item.dataIndex}
                                  className="input-field pr-9" 
                                  type={showPassword ? 'text' : 'password'}
                                  placeholder={`${item.title} оруулах`}
                                />
                                {
                                  values.password &&
                                  <button type='button' className='flex justify-center items-center absolute right-0 top-0 bottom-0 m-2 p-1.5 hover:bg-slate-200 rounded-full' onClick={() => setShowPassword(!showPassword)}>
                                    <ion-icon name={`eye-${showPassword ? '' : 'off-'}outline`}></ion-icon>
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                        )
                      default:
                        return (
                          item.type &&
                          <div key={i} xs={item.offset ? item.offset : colWidth}>
                            <div className='w-full mb-2'>
                              <span className='inline-block mb-1'>
                                {`${item.title} : `}
                              </span>
                              <div className='w-full'>
                                <Field
                                  label={item.title}
                                  disabled={item.fieldDisabled}
                                  name={item.dataIndex}
                                  type={item.type}
                                  className='input-field focus:bg-white bg-gray-50'
                                  placeholder={`${item.title} оруулах`}
                                  value={values[item.dataIndex]}
                                >
                                </Field>
                              </div>
                            </div>
                          </div>
                        )                    
                    }
                  })
                }
              </div>
              <div className='flex justify-end mb-3 text-sm'>
                {
                  (editable == null || editable) &&
                  <button
                    className='mr-3 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none transition-all'
                    type='submit'
                    loading={loading}
                    onClick={handleSubmit}
                    >
                    Хадгалах
                  </button>
                }
                {
                  closeModal &&
                  <button
                    type='button'
                    className='text-gray-500 bg-gray-200 hover:bg-gray-300 rounded-lg font-medium px-5 py-2.5 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-600 focus:outline-none transition-all'
                    onClick={() => {
                      resetForm()
                      closeModal()
                    }}
                    disabled={loading}
                    color="secondary">
                    Хаах
                  </button>
                }
              </div>
            </Form>
          )
        }
      }
    </Formik>
  );
}

export default CustomForm;