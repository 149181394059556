import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../assets/isa_logo.png'
import { IoCloseSharp, IoMenu } from 'react-icons/io5'
import { SystemContext } from '../utils/context'
import Sidebar from './sidebar'

const navLinks  = [
  {name: 'НҮҮР', link: '/'},
  {name: 'СУРГАЛТ', link: '/courses'},
  {name: 'БАГЦ', link: '/packages'},
  {name: 'ШАЛГАЛТ', link: '/exams'},
  // {name: 'ХОЛБОО БАРИХ', link: '/contact'},
]

function Navbar() {
  const [ isShow, setIsShow ] = useState(false)
  const context = useContext(SystemContext)
  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShow(false)
      }
    }
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  },[ref])

  const handleChangeTheme = () => {
    if(context.state.theme === 'dark'){
      context.action.changeTheme('light')
    }
    else{
      context.action.changeTheme('dark')
      
    }
  }

  const handleLoginBtn = () => {
    context.action.handleLoginModalToggle(true)
  }

  return (
    <div>
      <div className={`shadow-md w-full h-[72px] fixed top-0 left-0 transition-all ease-out duration-700 z-30 bg-white dark:bg-gray-800  ${context.state.showSidebar && 'ml-[-300px] mr-[300px]'}`}>
        <div className='max-w-7xl mx-auto h-full flex justify-between items-center sm:px-3'>
          <Link to={'/'}>
            <img src={logo} width={200} />
          </Link>
          <div className='flex items-center'>
            <nav className='xs:hidden md:block'>
              <ul className='flex items-center space-x-4 dark:text-white text-base '>
                { 
                  navLinks.map((nav, i) => (
                    <li key={i}>
                      <NavLink className={(navData) => navData.isActive ? 'text-sky-500' : 'hover:text-sky-500'} to={nav.href ? {pathname: nav.link} : nav.link}>{nav.name}</NavLink>
                    </li>
                  ))
                } 
                <li>
                  <a className='hover:text-sky-500' href='http://isa.edu.mn/%d0%b1%d0%bb%d0%be%d0%b3/' target={'_blank'}>
                    БЛОГ, НИЙТЛЭЛ
                  </a>
                </li>
                {
                  context.state.userToken ?
                  <li>
                    <div className={`relative inline-block text-left`}>
                      <div>
                        <button type="button" className="inline-flex justify-center items-center w-full shadow bg-blue-500 px-3 py-1 rounded-md text-white" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={() => setIsShow(!isShow)}>
                          {context.state.userInfo && context.state.userInfo.lastName} {context.state.userInfo && context.state.userInfo.firstName}
                          <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg>
                        </button>
                      </div>
                      {
                        isShow &&
                        <div ref={ref}className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-no3 bg-white dark:bg-gray-800 overflow-hidden" aria-labelledby="menu-button" tabIndex="-1">
                          <div role="none">
                            <Link to="myaccount" onClick={() => setIsShow(!isShow)} className="text-gray-700 block px-4 py-2 text-sm dark:text-gray-200 hover:bg-sky-100" role="menuitem" tabIndex="-2" id="menu-item-0">
                              Миний булан
                            </Link>
                            <Link to="mycourses" onClick={() => setIsShow(!isShow)} className="text-gray-700 block px-4 py-2 text-sm dark:text-gray-200 hover:bg-sky-100" role="menuitem" tabIndex="-1" id="menu-item-0">
                              Миний сургалт
                            </Link>
                            <Link to="myexams" onClick={() => setIsShow(!isShow)} className="text-gray-700 block px-4 py-2 text-sm dark:text-gray-200 hover:bg-sky-100" role="menuitem" tabIndex="-1" id="menu-item-0">
                              Миний шалгалт
                            </Link>
                            <button 
                              onClick={() => context.action.signOut()} 
                              type="button" 
                              className="text-gray-700 block w-full text-left px-4 py-2 text-sm dark:text-gray-200 hover:bg-sky-100" 
                              role="menuitem" 
                              tabIndex="-1" 
                              id="menu-item-3"
                            >
                              Гарах
                            </button>            
                          </div>
                        </div>
                      }
                    </div>
                  </li>
                  :
                  <li>
                    <button className='bg-blue-500 px-3 py-1 rounded-md text-white' onClick={() => handleLoginBtn()} >
                      НЭВТРЭХ
                    </button>
                  </li>
                }
                <li>
                  <div className='relative dark:text-white flex group'>
                    <Link className='relative dark:text-white flex group' to={'search'}>
                      <ion-icon name='search-outline' title='Гэрчилгээ хайх' style={{fontSize:'30px', color:'gray'}}></ion-icon>
                    </Link>          
                    <span className="sidebar-tooltip group-hover:scale-100">Гэрчилгээ хайх</span>
                  </div>
                </li>
                <li>
                  <a className='relative dark:text-white flex group' href='https://www.youtube.com/watch?v=Tv3rvyk9WzA&list=UUd_GCBZ3P1NUN4RurcvssTw' target='_blank'>
                    <ion-icon name='help-circle-outline' style={{fontSize:'30px', color:'gray'}}></ion-icon>
                    <span className="sidebar-tooltip group-hover:scale-100">Тусламж</span>
                  </a>          
                </li>
              </ul>
            </nav>
            {/* <button className='dark:text-white mx-3 flex' onClick={() => handleChangeTheme()}>
              <ion-icon name={`${context.state.theme === 'dark' ? 'sunny' : 'moon'}-outline`} style={{fontSize:'22px'}}></ion-icon>
            </button> */}
            <button className='sm:block md:hidden' onClick={() => context.action.handleSidebar(!context.state.showSidebar)}>
              {context.state.showSidebar ? 
              <IoCloseSharp size={30} className="md:hidden dark:text-white" ></IoCloseSharp>
              : 
              <IoMenu size={30} className='dark:text-white'/>
              }
            </button> 
          </div>
        </div>
      </div>
      <Sidebar navLinks={navLinks} handleLogin={handleLoginBtn}/>    
    </div>
  )
}

export default Navbar