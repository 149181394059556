import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper"
import { useNavigate } from 'react-router-dom';
import GenerateStar from 'components/GenerateStars';
import moment from 'moment';
import bg2 from 'assets/home/testimonial_bg.png'
import bg1 from 'assets/home/bg1.png'
import pattern1 from 'assets/home/course_pattern1.png'
import pattern2 from 'assets/home/course_pattern2.png'
import pattern3 from 'assets/home/course_pattern3.png'
import pattern4 from 'assets/home/course_pattern4.png'
import pattern5 from 'assets/home/course_pattern_2.png'
import pattern6 from 'assets/home/pattern_3.png'

function Home() {
  const [ data, setData ] = useState(null)
  const [ bannerData, setBannerData ] = useState([])
  const [ reviewData, setReviewData ] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    getData();
  },[])

  const getData = () => {
    axios({
      method: 'GET',
      url: 'home'
    }).then((res) => {
      setData(res.data)
      setBannerData(res.data.bannerData)
      setReviewData(res.data.reviewData)
    }).catch((err) => {
      console.log(err)
    })
  }
  
  return (
    <div className='bg-white'>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        speed={1000}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        draggable={false}
        loop
        navigation
        modules={[Autoplay, Navigation]}
      >
        {
          bannerData.map((item, i) => (
            <SwiperSlide key={i}>
              <img 
                onClick={() => item.link && navigate(`${item.link}`)}
                src={`${process.env.REACT_APP_ASSET_URL}${item.image}`} 
                style={{objectFit:'cover', objectPosition:'center', width: '100%', height:'auto', cursor: `${item.link && 'pointer'}`}}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>
      {
        data && 
        <>
          <div className=' py-[70px] relative'>
            <div className='absolute top-[10%] z-0'><img src={pattern1}/></div>
            <div className='absolute right-0 top-0'><img src={pattern2}/></div>
            <div className='absolute xs:-bottom-[3%]  sm:-bottom-[10%] left-[13%] z-[1]'><img src={pattern3}/></div>
            <div className='absolute right-0 bottom-[5%]' ><img src={pattern4}/></div>
            <div className='absolute right-[13%] top-[50%]' ><img src={pattern5}/></div>
            <div className='max-w-7xl mx-auto px-3 relative z-10'>
              <div className='flex flex-col items-center gap-1'>
                <div className='text-3xl font-medium dark:text-white'>
                  <div className='mb-2 text-slate-800'>ОНЦЛОХ СУРГАЛТУУД</div>
                  <div className='mx-auto w-2/3 border-blue-500 border-2 rounded-full'></div>
                </div>
              </div>
              <div className='flex justify-end items-center mb-3 mt-[40px]'>
                <Link className='bg-white border px-4 rounded flex items-center text-slate-600 hover:text-sky-400 dark:bg-gray-800 dark:text-white duration-150' to={'courses'}>
                  Бүх сургалт
                  <ion-icon name="chevron-forward" style={{marginLeft: '5px'}}></ion-icon>
                </Link>
              </div>
              <div className='flex flex-wrap -mx-2'>
                {
                  data.courseData.map((item, i) => (
                    <div className='sm:w-1/3 md:w-1/4 px-2 mb-4' key={i}>
                      <div className='card p-0 shadow-no2 hover:shadow-no3 duration-150'>
                        <img src={`${process.env.REACT_APP_ASSET_URL}${item.image}`} width='100%'/>
                        <div className='p-3'>
                          <Link to={`/courses/${item.urlId}`} className='card-title line-clamp-2 text-base leading-5'>{item.title}</Link>
                          <div className='flex items-center mt-3'>
                            {
                              item.avg_rating != 0 && 
                              <div className='flex items-center'>
                                <GenerateStar rating={item.avg_rating} color='gold'/>
                                <div className='ml-3 text-slate-400 font-medium'>{parseFloat(item.avg_rating).toFixed(1)}</div>
                              </div>
                            }
                            <div className='font-medium ml-auto text-slate-600'>{new Intl.NumberFormat().format(item.price)} ₮</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          {
            data.packageData.length > 0 &&
            <div className='py-[70px] relative'>
              <div className='absolute top-0 bottom-0 right-0 left-0 bg-contain bg-[#F7F9FB] bg-no-repeat h-[400px] ' style={{backgroundImage: `url(${bg1})`, backgroundSize: '100% 80%'}}></div>
              <div className='max-w-7xl mx-auto px-3 relative z-10'>
                <div className='flex flex-col items-center gap-1'>
                  <div className='text-3xl font-medium dark:text-white'>
                    <div className='mb-2 text-slate-800'>ОНЦЛОХ БАГЦУУД</div>
                    <div className='mx-auto w-2/3 border-blue-500 border-2 rounded-full'></div>
                  </div>
                </div>
                <div className='flex justify-end items-center mb-3 mt-[40px]'>
                  <Link className='bg-white border px-4 rounded flex items-center text-slate-600 hover:text-sky-400 dark:bg-gray-800 dark:text-white duration-150' to={'packages'}>
                    Бүх багц
                    <ion-icon name="chevron-forward" style={{marginLeft: '5px'}}></ion-icon>
                  </Link>
                </div>
                <div className='flex flex-wrap -mx-2'>
                  {
                    data.packageData.map((item, i) => (
                      <div className='sm:w-1/2 md:w-1/3 px-2' key={i}>
                        <div className='card shadow-no2 p-0 hover:shadow-no3 duration-150'>
                          <img src={`${process.env.REACT_APP_ASSET_URL}${item.image}`} width='100%'/>
                          <div className='p-3'>
                            <Link to={`/packages/${item.urlId}`} className='card-title'>{item.name}</Link>
                            <div className='font-medium text-slate-600'>{new Intl.NumberFormat().format(item.price)} ₮</div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div> 
              </div>
            </div>
          }
          {
            reviewData.length > 0 &&
            <div className='bg-center bg-no-repeat py-[60px] relative' style={{backgroundImage: `url(${bg2})`}}>
              <div className='absolute right-[5%] -top-[4%]'><img src={pattern6}/></div>
              <div className='max-w-7xl mx-auto px-1'>
                <div className='flex flex-col items-center gap-1 mb-8'>
                  <div className='text-3xl font-medium dark:text-white'>
                    <div className='mb-2 text-slate-800'>СУРАГЧДЫН СЭТГЭГДЭЛ</div>
                    <div className='mx-auto w-2/3 border-blue-500 border-2 rounded-full'></div>
                  </div>
                </div>
                <Swiper
                  slidesPerView={3}
                  autoplay={{
                    delay: 3000,
                  }}
                  spaceBetween={60}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,

                    },
                    481: {
                      slidesPerView: 2,
                      spaceBetween: 20
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 30
                    },
                    1024:{
                      slidesPerView: 3,
                      spaceBetween: 60
                    }
                  }}
                  loop
                  modules={[Autoplay]}
                  className='p-2'
                >
                  {
                    reviewData.map((item, i) => (
                      <SwiperSlide key={i}>
                        <div className='card shadow flex flex-col gap-3 items-center px-7'>
                          <div className='flex gap-3 items-center'>
                            <img 
                              onClick={() => item.link && navigate(`${item.link}`)}
                              src={`${process.env.REACT_APP_ASSET_URL}${item.image}`} 
                              className='rounded-full w-[60px] object-center object-cover'
                            />
                            <div>
                              <div className='font-medium'>{item.lastName} {item.firstName}</div>
                              <div className='text-sm text-gray-400'>{moment(item.createdAt).format('YYYY-MM-DD')}</div>
                            </div>
                          </div>
                          <p className='text-slate-500 font-medium'>{item.comment}</p>
                          <GenerateStar rating={item.rating} color='gold'/>
                        </div>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
            </div>
          }
        </>
      }
    </div>
  )
}

export default Home