import React, { useEffect, useRef, useState } from 'react'

function Tooltip({children, tooltipContent, title, onConfirm, onCancel, confirmText, cancelText, icon, modalStatus}) {
  const [ show, setShow ] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  },[ref])

  return (
    <div>
      <div className={`relative`}>
        <div aria-haspopup='true' aria-expanded='true' onMouseOver={() => setShow(true)} onMouseOut={() => setShow(false)}>
          {children}
        </div>
        {
          show && tooltipContent  &&
          <div ref={ref} className="absolute right-14 bottom-[120%] z-20 mt-2 rounded-md shadow-no3 bg-white dark:bg-gray-800 max-w-[150px]" role="menu" aria-orientation="vertical">
            {tooltipContent}
          </div>
        }
      </div>
    </div>
  )
}

export default Tooltip