import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios'
import { SystemContext } from '../../utils/context'
import { success } from 'assets/lotties';
import Lottie from 'lottie-web';
import toast from 'react-hot-toast';

function PaymentModal({show, qrData, detailData}) {
  const [ isPaid, setIsPaid ] = useState(false);

  const systemContext = useContext(SystemContext)
  const successRef = useRef(null)

  useEffect(() => {
    if(isPaid){
      Lottie.loadAnimation({
        container: successRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: success,
        rendererSettings:{
          // viewBoxSize: '0 0 1000 1000'
        }
      }).play()
    }
  },[isPaid])

  if(!show){
    return null;
  }
  
  const checkPayment = () => {
    axios({
      method: 'GET',
      url: `invoice/check/${qrData.id}`,
    }).then((res) => {
      if(res.data){
        setIsPaid(res.data)
      }
      else{
        toast.error('Төлөгдөөгүй байна')
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleCloseModal = () => {
    systemContext.action.handlePaymentModal(false)
  }

  return ReactDOM.createPortal(
    <div className="fixed top-0 h-full right-0 w-full bg-black/50 z-30 flex justify-center items-center">
      <div className='animate-fade-down'>
        <div className='flex flex-col relative w-[25rem] bg-white rounded-md text-black max-h-[40rem] dark:bg-gray-800 dark:text-white'>
          <button className='absolute top-0 right-0 p-3' type='button' onClick={() => handleCloseModal()}>
            <ion-icon name="close-circle-outline" size='large' style={{color: 'gray'}}></ion-icon>
          </button>
          <div className='border-b px-6 py-3 font-medium text-lg text-slate-500 dark:text-slate-300'>Төлбөр төлөлт</div>
          <div className='flex flex-col justify-center items-center py-2 px-4 text-center'>
            <div className='text-sm'>{detailData.title}</div>
            <div className='w-[250px] h-[250px] my-3 dark:border-slate-500'>
              {
                isPaid ?
                <>
                  <div ref={successRef} className='w-full'/>
                  <div className='text-green-400 font-medium -mt-4'>Амжилттай төлөгдлөө</div>
                </>
                :
                <img src={`data:image/png;base64,${qrData.qr_image}`} className='w-full border rounded' />
              }
            </div>
            <div className=' text-green-500 font-medium'>Төлөх үнэ: {new Intl.NumberFormat().format(detailData.price)} ₮</div>
            <div className='text-sm font-medium'>Нэхэмжлэлийн дугаар: <span>{qrData.invoiceNo}</span></div>
            <div className='px-6 py-3 '>
              {
                !isPaid &&
                <button className='bg-sky-400 text-white px-3 py-1 rounded-md' onClick={() => checkPayment()}>
                  Шалгах
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("payment-modal")
  )
}

export default PaymentModal
