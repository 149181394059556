import React from 'react'
import { ImgErrorEmployee } from '../NoImage'
import moment from 'moment'
import GenerateStar from '../GenerateStars'

function ReviewRating({reviews, rating}) {

  const ratingStars = (index) => {
    let starCount = 5 - index;
    let stars = []
    for(let i = 0; i < starCount; i++){ 
      stars.push(
        <ion-icon name="star" style={{color: 'gold', fontSize: 20}} key={`s${i}`}/>
        )
      }
      for(let i = 0; i < index; i++){
        stars.push(
        <ion-icon name="star-outline" style={{color: 'gold', fontSize: 20}} key={`c${i}`}/>
        )
      }
      return <>{stars}</>
    } 
    
    const reviewStars = (starCount) => {
      let stars = []
      for(let i = 0; i < starCount; i++ ){
        stars.push(
        <ion-icon name="star" style={{color: 'gold', fontSize: 15}} key={`r${i}`}/>
      )
    }
    return <>{stars}</>
  }
  return (
    <div>
      <div className='flex gap-5'>
        <div className='w-1/5 flex flex-col justify-center items-center border rounded dark:border-slate-700'>
            <div className='xs:text-4xl sm:text-6xl' style={{color:'gold'}}>{rating.avg_rating ? parseFloat(rating.avg_rating).toFixed(1) : 0}</div>
            <div className='xs:hidden md:block'>
              <GenerateStar rating={rating.avg_rating} size={24} color='gold'/>
            </div>
        </div>
        <div className='w-4/5' >
          {
            rating.data.map((item,i) => (
              <div className='flex gap-5 items-center' key={i}>
                <div className="w-2/3 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div className="bg-blue-600 h-2.5 rounded-full" style={{width: `${parseInt(item.percent)}%`}}></div>
                </div>
                <div className='flex w-1/3'>{ratingStars(i)} <span className='ml-2'>{parseInt(item.percent)}%</span></div>
              </div>
            ))
          }
        </div>
      </div>
      <div className='border-b my-4 dark:border-slate-700'></div>
      <div className='p-2'>
        {
          reviews.map((item, i) => (
            <div className='flex items-start mb-3' key={i}>  
              <img
                style={{width:'40px', heigth:'40px'}} 
                src={`${process.env.REACT_APP_ASSET_URL}${item.image}`}
                className='mr-2 overflow-hidden rounded-full object-cover object-center shrink-0'
                onError={(e) => {e.target.error = null; e.target.src = ImgErrorEmployee}}
              />
              <div>
                <div className='text-gray-500 font-medium' style={{fontSize: '14px'}}>
                  {item.lastName} {item.firstName} 
                </div>
                <div className='text-gray-400 flex items-center'>
                  <div className='mr-1'>{reviewStars(item.rating)}</div> 
                  •
                  <div className='ml-1 text-sm'>{moment(item.createdAt).format('YYYY-MM-DD hh:mm')}</div>
                </div>
                <div className='mt-2'>{item.comment}</div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default ReviewRating