import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { IoSchool, IoRibbonSharp, IoStar } from "react-icons/io5";
import ReviewRating from 'components/ReviewRating';
import { Disclosure } from '@headlessui/react';
import { SystemContext } from 'utils/context';
import PaymentModal from 'components/PaymentModal';
import Player from 'components/Player';
import Modal from 'components/Modal';

function ExamDetail() {
  const [ data, setData ] = useState(null)
  const [ qrData, setQrData ] = useState(null)
  const [ showModal, setShowModal ] = useState(false)

  const context = useContext(SystemContext)
  const {examId} = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    getData()
  },[])
  
  useEffect(() => {
    getData()
    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  },[examId])

  const getData = () => {
    axios({
      method:'GET',
      url: `examination/${examId}`
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleBuyBtn = () => {
    axios({
      method: 'POST',
      url: 'invoice/exam',
      data: {urlId: examId}
    }).then((res) => {
      setQrData(res.data)
      context.action.handlePaymentModal(true)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className='max-w-7xl max-w-7x mx-auto px-3'>
      <PaymentModal show={context.state.showPaymentModal} qrData={qrData} detailData={data}/>
      <Modal show={showModal} toggleShow={setShowModal} className='w-max-[500px]' title={'Анхаарна уу!'}>
        <div>
          <div className='border bg-orange-100 text-slate-600 p-2'>
            Та <b>Эхлэх</b> товч дарснаар шалгалт шууд эхлэх бөгөөд та бэлэн болсныхоо дараа шалгалтыг эхлүүлээрэй. Таньд амжилт хүсье !
          </div>
          <div className='flex justify-end gap-2 mt-3'>
            <button className='bg-sky-500 text-white px-3 py-1 rounded-md hover:bg-sky-400 duration-200' onClick={() => navigate(`../myexams/${examId}`)}>Эхлэх</button>
            <button className='bg-gray-400 text-white px-3 py-1 rounded-md hover:bg-gray-300 duration-200' onClick={() => setShowModal(false)}>Цуцлах</button>
          </div>
        </div>
      </Modal>
      <div className='py-6'>
        {
          data &&
          <>
            <div className='flex xs:flex-col md:flex-row gap-7'>
              <div className='flex flex-col md:w-2/3 xs:w-full gap-7'>
                <div className='card p-0'>
                  <div className='flex'>
                    <img src={`${process.env.REACT_APP_ASSET_URL}${data.image}`} width='100%'/>
                  </div>       
                  <div className='p-4'>
                    <div className='text-xl mb-3' >Танилцуулга</div>
                    <div className='prose prose-md contents dark:text-white text-sm' dangerouslySetInnerHTML={{__html: data.examDescr}}></div>
                  </div>        
                </div>
              </div>

                {/*-------------------------- SIDEBAR ------------------------------*/ }

              <div className='flex flex-col md:w-1/3 xs:w-full gap-7'>
                <div className='card flex flex-col '>
                  <h3 className='mt-0 text-lg leading-5 font-medium'>{data.title}</h3>
                  <ul className='divide-y dark:divide-slate-700'>
                    <li className='py-2 flex justify-between'>
                      <div className='flex items-center gap-2 text-gray-600 font-medium'>
                        <div className='flex text-lg'><ion-icon name="list-outline"/></div>
                        <div>Нийт асуулт:</div>
                      </div>
                      <div>{data.testCount}</div>
                    </li>
                    <li className='py-2 flex justify-between'>
                      <div className='flex items-center gap-2 text-gray-600 font-medium'>
                        <div className='flex text-lg'><ion-icon name="time-outline"/></div>
                        <div>Хугацаа:</div> 
                      </div>
                      <div>{data.testMinute} мин</div> 
                    </li>
                    <li className='py-2 flex justify-between'>
                      <div className='flex items-center gap-2 text-gray-600 font-medium'>
                        <div className='flex text-lg'><ion-icon name="pricetag-outline"/></div>
                        <div>Үнэ:</div>
                      </div>
                      <div>{new Intl.NumberFormat().format(data.price)} ₮</div>
                    </li>
                    <li className='py-2'>
                      <div className='flex items-center gap-2 text-gray-600 font-medium'>
                        <div className='flex text-lg'><ion-icon name="ribbon-outline"/></div>
                        <div>Гэрчилгээ:</div>
                      </div>
                      <div className='mt-2 flex justify-center'>
                        <iframe style={{ height:'400px'}} width='auto' src={`${process.env.REACT_APP_ASSET_URL}${data.certTemplate}#toolbar=0`}/>
                      </div>                      
                    </li>
                  </ul>
                  {
                    !data.paymentStatus ?
                    <button className='flex justify-center items-center bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto mt-4' onClick={handleBuyBtn}>
                      <ion-icon name="cart-outline" style={{fontSize:'18px'}}/>
                      <span className='ml-2' >ХУДАЛДАЖ АВАХ</span>
                    </button>
                    :
                    data.paymentStatus === 'Үүссэн' ?
                    <button className='flex justify-center items-center bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto mt-4' onClick={handleBuyBtn}>
                      <ion-icon name="card-outline" style={{fontSize:'18px'}}/>
                      <span className='ml-2' >ТӨЛӨХ</span>
                    </button>
                    :
                    data.paymentStatus === 'Төлөгдсөн' ?
                    <button className='flex justify-center items-center bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto mt-4' onClick={() => setShowModal(true)}> 
                      <span className='ml-2'>ШАЛГАЛТ ӨГӨХ</span>
                    </button>
                    : null
                  }
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default ExamDetail