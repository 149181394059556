import React from 'react'
import ReactStickyBox from 'react-sticky-box'

function Test() {
  return (
    <div class="bg-red-500 w-full h-screen" style={{display: "flex", alignItems: "flex-start"}}>
      <div className='sticky top-0 z-50' offsetTop={20} offsetBottom={20}>
        <code>position: sticky</code>
        <strong>Andrew Alfred</strong>
      </div>
      <div>Main Content</div>
    </div>
  )
}

export default Test