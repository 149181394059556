import React, { useEffect, useState } from 'react'
import { FaStar } from 'react-icons/fa'

function GenerateStar({rating, color='#ff9f3b', size=20, className}, props) {
  const [ stars, setStars ] = useState(null)
  useEffect(() => {
    if(rating){
      generate(rating)
    }
  },[rating])

  const generate = (rating) => {
    let fullStarCount = parseInt(rating)
    let halfStarPercent = (rating - fullStarCount) * 100
    let starArray = []
    for(let i = 0; i < fullStarCount; i++){
      starArray.push(<ion-icon name="star" style={{color: color, fontSize: size}} key={`g${i}`}/>)
    }
    if(halfStarPercent > 0){
      starArray.push(
        <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"  width={size} height={size} key='last'>
          <defs>
            <linearGradient id="half_grad">
              <stop offset={`${halfStarPercent}%`} stopColor={color}/>
              <stop offset={`${halfStarPercent}%`} stopColor="transparent" stopOpacity="1" />
            </linearGradient>
          </defs>
          <path d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z" stroke={color} strokeWidth={25} fill="url(#half_grad)" strokeLinejoin="round" />
        </svg>
      )
    }
    setStars(starArray)
  }
  
  return (
    <div style={{display:'flex'}} className={className}>
      {stars}
    </div>
  )
}

export default GenerateStar