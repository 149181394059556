import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Countdown from 'react-countdown';
import ls from 'utils/ls';
import Modal from 'components/Modal';
import ConfirmModal from 'components/ConfirmModal';

function TakeExamPage() {
  const {courseId, examId} = useParams()
  const [ data, setData ] = useState(null)
  const [ countDownTime, setCountDownTime ] = useState(null)
  const [tests, setTests] = useState([])
  const [ showModal, setShowModal ] = useState(false)
  const [ showResultModal, setShowResultModal ] = useState(false)
  const [ result, setResult ] = useState(null)
  const [ isFinished, setFinished ] = useState(false)

  useEffect(() => {
    if(courseId){ 
      getData(`mycourse/exam/create/${courseId}`)
    }
    else{
      getData(`myexam/create/${examId}`)
    }
  },[])

  useEffect(() => {
    const foo = (e) => {
      e.preventDefault()
      e.returnValue = '';
      return 'test'
    }
    
    window.addEventListener('popstate', foo)
    window.addEventListener('beforeunload', foo)
    
    return () => {
      window.removeEventListener('popstate', foo)
      window.removeEventListener('beforeunload', foo)
    }
  }, [])

  const getData = (url) => {
    axios({
      method: 'GET',
      url: `${url}`
    }).then((res) => {
      setData(res.data)
      setCountDownTime(Date.now() + res.data.leftTime)
      if(ls.get('tdt')){
        setTests(ls.get('tdt'))
      }
      else{
        let newData = [];
        let fixData = [...res.data.course_tests];
        fixData.map((test, i) => {
          test.checkedId = []
          test.isFilled = false;
          test.isCorrect = false;
          newData.push({...test})
        })
        ls.set('tdt', [...newData])
        setTests(newData)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const renderer = ({ hours, minutes, seconds, completed, formatted}) => {
    if (completed) {
      setShowModal(true)
      return <span className='flex justify-center mt-2 text-red-500 font-medium'>ШАЛГАЛТ ДУУСЛАА</span>;
    } else {
      // Render a countdown
      return <div className='flex justify-center mt-2'>
        <span className={`text-3xl font-medium ${formatted.minutes < 5 && 'text-red-500'}`}>{formatted.minutes}:{formatted.seconds}</span>
      </div>
    }
  };

  const handleChangeAnswer = (test, testIndex, answerIndex) => {
    let fixedData = [...tests];
    if(test.testType == 'Нэг сонголттой'){
      fixedData[testIndex].checkedId = [fixedData[testIndex].course_test_answers[answerIndex].id];
      fixedData[testIndex].isFilled = true;
    }
    else{
      if(!fixedData[testIndex].checkedId.find((id) => id == fixedData[testIndex].course_test_answers[answerIndex].id)){
        fixedData[testIndex].checkedId.push(fixedData[testIndex].course_test_answers[answerIndex].id);
        fixedData[testIndex].isFilled = true
      }
      else{
        let tmp = fixedData[testIndex].checkedId.filter((e) => e !== fixedData[testIndex].course_test_answers[answerIndex].id)
        fixedData[testIndex].checkedId = tmp
      }
    }      
    setTests(fixedData)
    ls.set('tdt', [...fixedData])
  }

  const finishTest = () => {
    let tmp = []
    tests.map((test, i) => {
      tmp.push({testId: test.id, answers: [...test.checkedId]})
    })
    if(examId){
      axios({
        method: 'POST',
        url: `myexam/end/${examId}`,
        data: tmp
      }).then((res) => {
        setResult(res.data)
        setShowResultModal(true)
        ls.remove('tdt')
        setFinished(true)
      }).catch((err) => {
        console.log(err)
      })
    }
    else{
      axios({
        method: 'POST',
        url: `mycourse/exam/end/${courseId}`,
        data: tmp
      }).then((res) => {
        setResult(res.data)
        setShowResultModal(true)
        ls.remove('tdt')
        setFinished(true)
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  return (
    <div className='max-w-7xl mx-auto px-3 py-6'>
      {
        tests.length > 0 && !isFinished &&
        <div className='flex md:flex-row xs:flex-col gap-4'>
          <div className='md:w-1/3 '>
            <div className='card sticky top-24'>
              <div className='text-xl font-medium leading-6'>
                {data.title}
              </div>
              <div className='flex flex-wrap -mx-1 mt-3'>
                {
                  tests.map((test, i) => (
                    <div className='p-1' key={i}>
                      <a href={`#${test.id}`} >
                        <div className={`w-[50px] text-center text-white rounded font-medium ${test.isFilled ? 'bg-sky-500' : 'bg-gray-400'}`}>{i+1}</div>
                      </a>
                    </div>
                  ))
                }
              </div>
              {
                countDownTime &&
                <Countdown  date={countDownTime} renderer={renderer}/>
              }
              <div className='flex justify-end'>
                <button type='button' className='py-1 px-2 bg-green-500 hover:bg-green-400 rounded text-white' onClick={finishTest}>Дуусгах</button>
              </div>
            </div>
          </div>
          <div className='card md:w-2/3 flex flex-col'>
            {
              tests.map((test, i) => (
                <div className='mb-3' key={i} id={test.id} style={{scrollMarginBlockStart: '80px'}}>
                  <div className='flex gap-2'>
                    <div className='text-2xl'>{i+1}.</div>
                    <div>
                      <div dangerouslySetInnerHTML={{__html: test.question}}/>
                      <div className='flex flex-col'>
                        {
                          test.course_test_answers.map((answer, idx ) => (
                            <div key={idx} className='flex items-center gap-1 mt-1'>
                              <input 
                                id={answer.id} 
                                name={test.id} 
                                checked={test.checkedId.find((checkId) =>checkId == answer.id)}
                                type={test.testType == 'Нэг сонголттой' ? 'radio' : 'checkbox'}
                                onChange={() => handleChangeAnswer(test, i, idx)}
                              />
                              <label htmlFor={answer.id}>{answer.answer}</label>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      }
      <ConfirmModal 
        show={showModal} 
        showToggle={setShowModal} 
        title='Шалгалт дууслаа' 
        okText='Хариу илгээх'
        okBtnClass='bg-sky-500 hover:bg-sky-400 text-white'
        closeBtnHide
        handleOk={finishTest}
      />
      <Modal show={showResultModal} toggleShow={setShowResultModal} className='w-[500px]'>
        {
          result &&
          <div className='flex flex-col items-center gap-2'>
            <div className={`text-4xl ${result.status == 'Тэнцээгүй' ? 'text-red-500' : 'text-green-500'}`}>{result.status}</div>
            <div  className={`text-4xl`}>{result.score}</div>
            <div className='flex flex-wrap gap-1'>
              {
                result.result.map((test, i) => (
                  <div key={i} className={`px-2 w-[20px] font-medium ${test.trueAnswers == 1 ? 'text-green-500' : 'text-red-500'}`}>{i+1}</div>
                ))
              }
            </div>
          </div>
        }
      </Modal>
    </div>
  )
}

export default TakeExamPage