import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { IoSchool, IoRibbonSharp, IoStar } from "react-icons/io5";
import ReviewRating from 'components/ReviewRating';
import { Disclosure } from '@headlessui/react';
import { SystemContext } from 'utils/context';
import PaymentModal from 'components/PaymentModal';
import Player from 'components/Player';
import Modal from 'components/Modal';
import moment from 'moment';

function MyPassedCourse() {
  const [ data, setData ] = useState(null)
  const [ qrData, setQrData ] = useState(null)
  const [ showModal, setShowModal ] = useState(false)

  const context = useContext(SystemContext)
  const {courseId} = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    getData()
  },[])
  
  useEffect(() => {
    getData()
    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  },[courseId])

  const getData = () => {
    axios({
      method:'GET',
      url: `mycourse/passed/${courseId}`
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className='max-w-7xl max-w-7x mx-auto px-3'>
      <PaymentModal show={context.state.showPaymentModal} qrData={qrData} detailData={data}/>
      <Modal show={showModal} showToggle={setShowModal} className='w-[500px]' title={'Анхаарна уу!'}>
        <div>
          <div className='border bg-orange-100 text-slate-600 p-2'>
            Та <b>Эхлэх</b> товч дарснаар шалгалт шууд эхлэх бөгөөд та бэлэн болсныхоо дараа шалгалтыг эхлүүлээрэй. Таньд амжилт хүсье !
          </div>
          <div className='flex justify-end gap-2 mt-3'>
            <button className='bg-sky-500 text-white px-3 py-1 rounded-md hover:bg-sky-400 duration-200' onClick={() => navigate(`../myexams/${courseId}`)}>Эхлэх</button>
            <button className='bg-gray-400 text-white px-3 py-1 rounded-md hover:bg-gray-300 duration-200' onClick={() => setShowModal(false)}>Цуцлах</button>
          </div>
        </div>
      </Modal>
      <div className='py-6'>
        {
          data &&
          <>
            <div className='flex xs:flex-col md:flex-row gap-7'>
              <div className='flex flex-col md:w-2/3 xs:w-full gap-7'>
                <div className='card p-0'>
                  <div className='flex'>
                    <img src={`${process.env.REACT_APP_ASSET_URL}${data.image}`} width='100%'/>
                  </div>       
                  <div className='p-4'>
                    <div className='text-xl mb-3' >Танилцуулга</div>
                    <div className='prose prose-md contents dark:text-white text-sm' dangerouslySetInnerHTML={{__html: data.examDescr}}></div>
                  </div>        
                </div>
                <div className='card'>
                  <div className='text-xl mb-3'>Шалгалтын түүх</div>
                  <table className='border'>
                    <thead className='border-b'>
                      <tr className='font-medium'>
                        <td className='px-2'>Огноо</td>
                        <td className='px-2'>Авсан оноо</td>
                        <td className='px-2'>Тэнцсэн эсэх</td>
                        <td className='px-2'>Тайлбар</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data.examLog.map((item, i) => (
                          <tr key={i} className='border-b text-center text-sm'>
                            <td className='px-2'>{moment(item.examStartDate).format('YYYY-MM-DD') }</td>
                            <td className='px-2'>{item.examScore}</td>
                            <td className={`px-2 ${item.status == 'Тэнцээгүй' ? 'text-red-500' : 'text-green-500'}`}>{item.status}</td>
                            <td className='px-2'>{item.descr}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>

                {/*-------------------------- SIDEBAR ------------------------------*/ }

              <div className='flex flex-col md:w-1/3 xs:w-full gap-7'>
                <div className='card flex flex-col '>
                  <h3 className='mt-0 text-lg leading-5 font-medium'>{data.title}</h3>
                  <ul className='divide-y dark:divide-slate-700'>
                    <li className='py-2 flex justify-between'>
                      <div className='flex items-center gap-2 text-gray-600 font-medium'>
                        {/* <div className='flex text-lg'><ion-icon name="pricetag-outline"/></div> */}
                        <div>Үнэ:</div>
                      </div>
                      <div>{new Intl.NumberFormat().format(data.price)} ₮</div>
                    </li>
                    {
                      data.expireDate &&
                      <li className='py-2 flex justify-between'>
                        <div className='flex items-center gap-2 text-gray-600 font-medium'>
                          <div className='flex text-lg'><ion-icon name="time-outline"/></div>
                          <div>Дуусах хугацаа:</div> 
                        </div>
                        <div>{data.expireDate}</div> 
                      </li>
                    }
                    <li className='py-2 flex justify-between'>
                      <div className='flex items-center gap-2 text-gray-600 font-medium'>
                        <div>Төлөв:</div>
                      </div>
                      <div className={data.status == 'Төгссөн' ? 'text-green-500 font-medium' : ''}>{data.status}</div>
                    </li>
                    <li className='py-2 flex justify-between'>
                      <div className='flex items-center gap-2 text-gray-600 font-medium'>
                        <div>Авсан оноо:</div> 
                      </div>
                      <div>{data.examScore}</div> 
                    </li>
                    <li className='py-2'>
                      <div className='flex items-center gap-2 text-gray-600 font-medium'>
                        <div className='flex text-lg'><ion-icon name="ribbon-outline"/></div>
                        <div className='flex w-full gap-2 items-center justify-between'>
                          <div>Гэрчилгээ:</div>
                          <div>{data.certNumber}</div>
                        </div>
                      </div>
                      <div className='mt-2 flex justify-center'>
                        <iframe style={{ height:'400px'}} width='auto' src={`${process.env.REACT_APP_ASSET_URL}${data.certifcate}#toolbar=0&view=fitH`}/>
                      </div> 
                    </li>
                  </ul>
                  <div className='flex justify-center'>
                    <a href={`${process.env.REACT_APP_ASSET_URL}${data.certifcate}`} target='_blank'>
                      <button className='bg-sky-400 px-2 py-1 rounded text-white mt-2 mx-auto'>
                        Татах
                      </button>       
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default MyPassedCourse