import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Exams() {
  const [ data, setData ] = useState([])

  useEffect(() => {
    getData()
  },[])

  const getData = () => {
    axios({
      method: 'GET',
      url: 'examination'
    }).then((res) => {
       setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className='max-w-7xl mx-auto px-3'>
      <div className='py-6'>
        <h2 className='mt-0 font-medium dark:text-white'>Шалгалт</h2>
        <div className='flex flex-wrap -mx-2'>
          {
            data.map((item, i) => (
              <div className='sm:w-1/2 md:w-1/3 px-2 my-4' key={i}>
                <div className='card p-0'>
                  <img src={`${process.env.REACT_APP_ASSET_URL}${item.image}`} width='100%'/>
                  <div className='p-3'>
                    <Link to={`/exams/${item.urlId}`} className='card-title'>{item.title}</Link>
                    <div className='text-sm mt-2'>
                      {item.topicNames.split(',').map((item, i) => (
                        <span className='bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 mb-2 dark:bg-gray-700 dark:text-gray-300' key={i}>{item}</span>
                      ))}
                    </div>
                    <div className='font-medium'>{new Intl.NumberFormat().format(item.price)} ₮</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Exams