import React from 'react'
import style from './style.module.css'
function FormError(error) {
  return (
    <div className={style.formError}>
      {error}
    </div>
  )
}

export default FormError
