import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IoClose, IoPeople, IoSearch } from "react-icons/io5";
import GenerateStar from 'components/GenerateStars';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import playIcon from'assets/play-overlay.png'

function Courses() {
  const [ courses, setCourses ] = useState([])
  const [ packages, setPackages ] = useState([])
  const [ topics, setTopics ] = useState([])
  const [ selectedTopic, setSelectedTopic ] = useState(null)
  const [ selectedPackage, setSelectedPackage ] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    getData();
    getPackages();
    getTopic();
  },[])
  
  useEffect(() => {
    getData();
  },[selectedTopic, selectedPackage])
  
  
  const getData = () => {
    let url = '';
    if(selectedTopic && selectedPackage){
      url = `?topicId=${selectedTopic.urlId}&packageId=${selectedPackage.urlId}`
    }
    else if(selectedTopic){
      url = `?topicId=${selectedTopic.urlId}`
    }
    else if(selectedPackage){
      url = `?packageId=${selectedPackage.urlId}`

    }
    axios({
      method: 'GET',
      url: `course${url}`
    }).then((res) => {
      let tmp = res.data;
      let topics;
      tmp.map((item, i) => {
        topics = item.topicNames.split(',')
        item.topicList = topics
      })
      setCourses(tmp)
    }).catch((err) => {
      console.log(err)
    })
  }

  const getPackages = () => {
    axios({
      method: 'GET',
      url: 'package'
    }).then((res) => {
      setPackages(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  
  const getTopic = () => {
    axios({
      method: 'GET',
      url: 'topic'
    }).then((res) => {
      setTopics(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  // const onChanging = (e) => {
  //   if ('URLSearchParams' in window) {
  //     var searchParams = new URLSearchParams(window.location.search);
  //     searchParams.set("foo", e);
  //     window.location.search = searchParams.toString();
  //   }
  // }

  return (
    <div className='max-w-7xl mx-auto px-3'>
      <div className='py-6'>
        <h2 className='mt-0 font-medium dark:text-white'>Сургалт</h2> 
        <div className='flex flex-col gap-7'>
          <div className='flex flex-col w-full gap-7'>
            <div className='card p-4 flex justify-between items-center dark:bg-gray-800 dark:text-white'>
              <div className='flex gap-2 items-center'>
                {selectedTopic && 
                  <div className='flex items-center font-bold bg-blue-100 text-blue-800 text-md px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800'>
                    <span className='mr-3'>{selectedTopic.title}</span>
                    <button className='hover:bg-blue-300 cursor-pointer rounded-full flex' onClick={() => setSelectedTopic(null)}>
                      <IoClose size={20}/>
                    </button>
                  </div>
                } 
                {selectedPackage && 
                  <div className='flex items-center font-bold bg-blue-100 text-blue-800 text-md px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800'>
                    <span className='mr-3'>{selectedPackage.name}</span>
                    <button className='hover:bg-blue-300 cursor-pointer rounded-full flex' onClick={() => setSelectedPackage(null)}>
                      <IoClose size={20}/>
                    </button>
                  </div>
                } 
                <div className='ml-2 text-gray-600 dark:text-gray-400'>Нийт {courses.length} сургалт</div>
              </div>
              {/* <div className='flex items-center border rounded dark:border-slate-700 py-2 px-3'>
                <input type={'search'} placeholder='Сургалт хайх' className='focus:outline-none dark:bg-gray-800'></input>
                <button className='flex'>
                  <IoSearch style={{fontSize: '22px', color:'#7d7e7f'}}></IoSearch>
                </button>
              </div> */}
            </div>
            <div className='flex gap-4'>
              <div className='xs:hidden md:block w-1/5'>
                <div className='card'>
                  <div className='mb-2 font-medium'>АНГИЛАЛ</div>                
                  <ul className='text-sm divide-y'>
                    {
                      topics.map((topic, i) => (
                        <li key={i} className='py-1 text-sky-600 hover:text-sky-800 duration-150'>
                          <button onClick={() => setSelectedTopic(topic)}>{topic.title}</button>
                        </li>
                      ))
                    }
                  </ul>
                </div>
                <div className='card flex flex-col mt-4'>
                  <div className='mb-2 font-medium'>БАГЦ</div>      
                  <ul className='text-sm divide-y'>
                    {
                      packages.map((item, i) => (
                        <li key={i} className='py-1 text-sky-600 hover:text-sky-800 duration-150'>
                          <button onClick={() => setSelectedPackage(item)}>{item.name}</button>
                        </li>
                      ))
                    }
                  </ul>
                </div>      
              </div>
              <div className='flex flex-col gap-7 md:w-4/5 xs:w-full'>
                {
                  courses.map((course, index) => (
                    <div className='course-list-card' key={index}>
                      <div className='xs:w-full md:w-1/4 flex relative cursor-pointer' onClick={() => navigate(`${course.urlId}`)}>
                        <img src={`${process.env.REACT_APP_ASSET_URL}${course.image}`} width='100%' height='100%' className='object-cover object-center'/>
                      </div>
                      <div className='sm:w-3/4 flex md:flex-row xs:flex-col'>
                        <div className='md:w-2/3 xs:w-full p-4 flex flex-col justify-between'>                          
                          <div>
                            <Link to={`/courses/${course.urlId}`} className='card-title line-clamp-2' >{course.title}</Link>
                            <div className='text-gray-500 my-3 text-sm line-clamp-4'>{course.about}</div>
                          </div>
                          <div className='text-sm'>
                            {course.topicList.map((item, i) => (
                              <span className='bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 mb-2 dark:bg-gray-700 dark:text-gray-300' key={i}>{item}</span>
                            ))}
                          </div>
                        </div>
                        <div className='md:w-1/3 xs:w-full px-6 sm:my-4 border-l flex flex-col justify-center text-xs'>
                            <Link to={`${course.urlId}`}>
                              <button className='w-full bg-success text-white px-8 py-2 rounded hover:opacity-90 duration-150 my-1'>
                                ХУДАЛДАЖ АВАХ
                              </button>
                            </Link>
                            <Link to={`${course.urlId}`}>
                              <button className='w-full bg-sky-500 text-white px-8 py-2 rounded hover:opacity-90 duration-150 my-1'>
                                ҮНЭГҮЙ ҮЗЭХ
                              </button>
                            </Link>
                          <div className='flex text-sm my-1'>
                            <GenerateStar rating={course.avg_rating} color='gold' size={16}/>
                            <span className='ml-2 text-gray-400 font-bold'>
                              {parseFloat(course.avg_rating).toFixed(1)} <span className=' font-normal text-black dark:text-white'>(үнэлгээ)</span>
                            </span>
                          </div>
                          <div className='flex items-center text-sm my-1'>
                            <IoPeople name="people" className='text-gray-400'></IoPeople>
                            <div className='ml-2 '>63 сурагчид</div>
                          </div>
                          <div className='text-sm my-1'>Сургалтын төлбөр: <span className='font-bold'>{Intl.NumberFormat().format(course.price)} ₮</span></div>
                        </div>
                      </div>
                    </div>
                  ))
                }      
              </div>
            </div>
          </div>

          {/* -----------------------  Packages  ------------------------ */}

          <div className='flex flex-col w-full gap-7'>
               
          </div>
        </div>
      </div>
    </div>
  )
}

export default Courses