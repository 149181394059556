import React from 'react'

function Policy({data, finished, setCheck, isChecked}) {
  return (
    <div className='flex flex-col relative w-[35rem] bg-white  rounded-md text-black max-h-[40rem] '>
      <div className='border-b px-6 py-3 font-medium text-lg text-slate-500'>Үйлчилгээний нөхцөл</div>
      <div className='px-6 py-3 overflow-auto' dangerouslySetInnerHTML={{__html: data}}/>
      <div className='border-t px-6 py-3 '>
        <div className='font-medium flex items-center'>
          <input id="remember" name="remember" type="checkbox" checked={isChecked}  onChange={setCheck}/>
          <div className='text-sm font-normal ml-3'>Нөхцөл зөвшөөрөх</div>
        </div>
        <button className='float-right bg-sky-400 text-white px-3 py-1' onClick={() => finished(false)}>Батлах</button>
      </div>
    </div>
  )
}

export default Policy