import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { IoSchool, IoRibbonSharp, IoStar } from "react-icons/io5";
import ReviewRating from 'components/ReviewRating';
import { Disclosure } from '@headlessui/react';
import { SystemContext } from 'utils/context';
import PaymentModal from 'components/PaymentModal';
import Player from 'components/Player';

function CourseDetail() {
  const [ data, setData ] = useState(null)
  const [ qrData, setQrData ] = useState(null)
  const context = useContext(SystemContext)
  const {courseId} = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    getData()
  },[])
  
  useEffect(() => {
    getData()
    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  },[courseId])

  const getData = () => {
    axios({
      method:'GET',
      url: `course/${courseId}`
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleBuyBtn = () => {
    axios({
      method: 'POST',
      url: 'invoice/course',
      data: {urlId: courseId}
    }).then((res) => {
      setQrData(res.data)
      context.action.handlePaymentModal(true)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className='max-w-7xl mx-auto px-3'>
      <PaymentModal show={context.state.showPaymentModal} qrData={qrData} detailData={data}/>
      <div className='py-6'>
        {
          data &&
          <>
            <div className='flex xs:flex-col md:flex-row gap-7'>
              <div className='flex flex-col md:w-2/3 xs:w-full gap-7'>
                <h2 className='m-0 font-medium dark:text-white'>{data && data.title}</h2>
                <div className='card p-0 pt-5'>
                  <div className='flex px-2 divide-x xs:text-xs sm:text-base'>
                    <div className='flex items-center px-3 w-1/3'>
                      <IoSchool size={24} style={{ color: '#1e83f0'}}></IoSchool>
                      <div className='px-4'>
                        <div className='text-gray-400'>Багш</div>
                        {
                          data.course_teachers.map((teacher, i) => (
                            <div key={i}>
                              <Link to={`../teacher/${teacher.teacherId}`} replace className='text-sky-700'>{teacher.lastName} {teacher.firstName}</Link>
                              {/* <div className='text-sm text-gray-500'>{teacher.position}</div> */}
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className='flex items-center px-3 w-1/3'>
                      <IoRibbonSharp size={24} style={{ color: '#1e83f0'}}></IoRibbonSharp>
                      <div className='px-4'>
                        <div className='text-gray-400'>Ангилал</div>
                        <div className='flex flex-wrap text-sm'>
                          {
                            data.course_topics[0].title
                          }
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center px-3 w-1/3'>
                      <IoStar size={24} style={{ color: 'gold'}}></IoStar>
                      <div className='px-4'>
                        <div className='text-gray-400'>Rating</div>
                        <div>4.5</div>
                      </div>
                    </div>                  
                  </div>
                  <div className='flex mt-4'>
                    <img src={`${process.env.REACT_APP_ASSET_URL}${data.image}`} width='100%'/>
                  </div>               
                </div>

                {/*-------------------------- COURSE INFO, DESCRIPTION ------------------------------*/ }
             
                <div className='card'>
                  <div className='text-xl mb-3' >Сургалтын танилцуулга</div>
                  <div className='prose prose-md contents dark:text-white text-sm' dangerouslySetInnerHTML={{__html: data.descr}}></div>
                  {
                    !data.paymentStatus ?
                    <button className='flex justify-center items-center bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto mt-4' onClick={handleBuyBtn}>
                      <ion-icon name="cart-outline" style={{fontSize:'18px'}}/>
                      <span className='ml-2' >ХУДАЛДАЖ АВАХ</span>
                    </button>
                    :
                    data.paymentStatus === 'Үүссэн' ?
                    <button className='flex justify-center items-center bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto mt-4' onClick={handleBuyBtn}>
                      <ion-icon name="card-outline" style={{fontSize:'18px'}}/>
                      <span className='ml-2' >ТӨЛӨХ</span>
                    </button>
                    :
                    data.paymentStatus === 'Төлөгдсөн' ?
                    <button className='flex justify-center items-center bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto mt-4' onClick={() => navigate(`../watchcourse/${data.urlId}`)}>
                      <ion-icon name="play-outline" style={{fontSize:'18px'}}/>
                      <span className='ml-2'>СУРГАЛТ ҮЗЭХ</span>
                    </button>
                    : null
                  }
                </div>
                <Player src={data.fileURL} poster={data.thumbnail}/>
                <div className='card'>
                  <div className='text-xl mb-3' >Сургалтын агуулга</div>    
                  {
                    data.courseSection.map((item, i) => (
                      <Disclosure key={i}>
                        {
                          ({open}) => (
                            <>
                              <Disclosure.Button className="flex justify-between items-center p-2 px-4 w-full font-medium text-left text-gray-900 bg-gray-100 border border-b-[0.5px] border-gray-200 dark:border-gray-700 dark:text-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800">
                                <span>{item.title}</span>
                                <ion-icon name={`chevron-${open ? 'up': 'down'}-outline`}></ion-icon>
                              </Disclosure.Button>
                              <Disclosure.Panel className="text-gray-500 border-x p-2 px-4">
                                {
                                  item.course_section_videos.map((video, i) => (
                                    <div key={i} className='text-sm py-2 flex items-center'>
                                      <ion-icon name="play-circle-outline" style={{fontSize: '18px'}}></ion-icon>
                                      <span className='ml-3'>{video.title}</span>
                                    </div>
                                  ))
                                }
                                {
                                  item.course_tests.map((test, i) => (
                                    <div key={i} className='text-sm py-2 flex items-center'>
                                      <ion-icon name="reader-outline" style={{fontSize: '18px'}}></ion-icon>
                                      <span className='ml-3'>{test.test}</span>
                                    </div>
                                  ))
                                }
                              </Disclosure.Panel>
                            </>
                          )
                        }
                      </Disclosure>                 
                    ))
                  }       
                </div>
                {/*-------------------------- REVIEW, RATING ------------------------------*/ }
             
                <div className='card'>
                  <div className='text-xl mb-3' >Сургалтын үнэлгээ</div>
                  <ReviewRating reviews={data.course_review} rating={data.course_rating}/>
                </div>
              </div>

                {/*-------------------------- SIDEBAR ------------------------------*/ }

              <div className='flex flex-col md:w-1/3 xs:w-full gap-7'>
                {/* <div className='card flex flex-col'>
                  <h3 className='font-normal mt-0'>Хичээл орох багш</h3>
                  {
                    data.course_teachers.map((teacher, i) => (
                      <div className='flex gap-3'>
                        <img width={100} src={`${process.env.REACT_APP_ASSET_URL}${teacher.image}`}/>
                        <div>
                          <div>{teacher.lastName} {teacher.firstName}</div>
                          <div className='text-sm text-gray-500'>{teacher.position}</div>
                        </div>
                      </div>
                    ))
                  }
                </div> */}
                <div className='card flex flex-col'>
                  <h3 className='font-normal mt-0'>Бусад сургалт</h3>
                  <ul className='divide-y dark:divide-slate-700'>
                    {
                      data.suggestCourse.map((item, i) => (
                        <li className='py-2 flex gap-2' key={i}>
                          <div className='w-[30%]'>
                            <img src={`${process.env.REACT_APP_ASSET_URL}${item.image}`} ></img>
                          </div>
                          <div className='w-[70%] text-sm'>
                            <Link className='font-medium line-clamp-3' to={`/courses/${item.urlId}`}>{item.title}</Link>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default CourseDetail