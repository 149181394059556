 import { useEffect, useState } from "react";

const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9"
    
};

function RateInput({onSubmit, edit}) {
  const [ review, setReview ] = useState(null)
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0)

  useEffect(() => {
    if(edit){
      setCurrentValue(edit.rating)
      setReview(edit.comment)
    }
  },[edit])

  const handleClick = value => {
    setCurrentValue(value)
  }

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue)
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }


  return (
    <div className="flex flex-col items-center gap-3">
      <div className="flex">
        {stars.map((_, index) => {
          return (
            <div key={index} >
              <ion-icon name="star" 
                style={{fontSize: '25px', color: (hoverValue || currentValue) > index ? 'gold' : colors.grey, cursor:'pointer'}} 
                onClick={() => handleClick(index + 1)}
                onMouseOver={() => handleMouseOver(index + 1)}
                onMouseLeave={handleMouseLeave}
              />
            </div>            
          )
        })}
      </div>
      <textarea id="message" rows="4" className="p-2.5 w-full text-sm  bg-gray-50 rounded-lg border focus:outline-none focus:border-blue-300 resize-none border-gray-300  dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="Сэтгэгдэл бичих..." value={review} onChange={(e) => setReview(e.target.value)}/>
      <button
        className="px-3 py-1 rounded-md border bg-green-600 text-white"
        onClick={() => onSubmit(review, currentValue)}
      >
        Хадгалах
      </button>
      
    </div>
  );
};

export default RateInput;