import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios'
import { SystemContext } from '../../utils/context'
import toast from 'react-hot-toast';

function PrivacyPolicyModal({show}) {
  const [ data, setData ] = useState(null)
  const [ checked, setChecked ] = useState(false);

  const systemContext = useContext(SystemContext)

  useEffect(() => {
    axios({
      method: 'GET',
      url: 'policy'
    }).then((res) => {
      setData(res.data.policy)
    }).catch((err) => {
      console.log(err)
    })
  },[show])

  if(!show){
    return null;
  }

  const handleSubmit = () => {
    if(checked){
      axios({
        method: 'GET',
        url: 'policyconfirm',
      }).then((res) => {
        toast.success('Баярлалаа')
        systemContext.action.handleShowPrivacyPolicy(false)
        window.location.reload(false)
      }).catch((err) => {
        console.log(err)
      })
    }
    else{
      systemContext.action.handleShowPrivacyPolicy(false)
      window.location.reload(false)
    }
  }

  const handleCloseModal = () => {
    systemContext.action.handleShowPrivacyPolicy(false)
    window.location.reload(false)
  }

  return ReactDOM.createPortal(
    <div className="fixed top-0 h-full right-0 w-full bg-black/50 z-20 flex justify-center items-center">
      <div className='animate-fade-down'>
        <div className='flex flex-col relative w-[35rem] bg-white  rounded-md text-black max-h-[40rem] '>
          <button className='absolute top-0 right-0 p-3' type='button' onClick={() => handleCloseModal()}>
            <ion-icon name="close-circle-outline" size='large' style={{color: 'gray'}}></ion-icon>
          </button>
          <div className='border-b px-6 py-3 font-medium text-lg text-slate-500'>Үйлчилгээний нөхцөл</div>
          <div className='px-6 py-3 overflow-auto' dangerouslySetInnerHTML={{__html: data}}/>
          <div className='border-t px-6 py-3 '>
            <div className='font-medium flex items-center'>
              <input id="remember" name="remember" type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)}/>
              <button className='text-sm font-normal ml-3 hover:text-sky-500 duration-150' onClick={() => setChecked(!checked)}>Нөхцөл зөвшөөрөх</button>
            </div>
            <button 
              disabled={!checked} 
              className='float-right bg-sky-400 text-white px-3 py-1 disabled:bg-gray-300 disabled:cursor-not-allowed' 
              onClick={() => handleSubmit()}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("privacyPolicy-modal")
  )
}

export default PrivacyPolicyModal
