import React, { useContext } from 'react'
import PrivacyPolicyModal from 'components/PrivacyPolicyModal';
import { Routes, Route } from 'react-router-dom';
import { SystemContext } from './utils/context';

import LoginModal from './components/LoginModal';
import Footer from './layouts/footer';
import Navbar from './layouts/navbar';
import CourseDetail from './pages/courseDetail';
import Courses from './pages/courses';
import Home from './pages/home';
import PackageDetail from './pages/packageDetail';
import Packages from './pages/packages';
import TeacherProfile from 'pages/teacherProfile';
import MyCourses from 'pages/myCourses';
import WatchCourse from 'pages/watchCourse';
import MyAccount from 'pages/myAccount';
import Exams from 'pages/exams';
import ExamDetail from 'pages/examDetail';
import MyExam from 'pages/myExam';
import TakeExamPage from 'pages/takeExamPage';
import Test from 'pages/test';
import MyExamDetail from 'pages/myExamDetail';
import CertificateSearch from 'pages/certificateSearch';
import MyPassedCourse from 'pages/myPassedCourse';
import ConflictModal from 'components/ConflictModal';
import ViewCertificate from 'pages/viewCertificate';

function App() {
  const context = useContext(SystemContext)
  
  return (
    <>
      <Navbar/>
      <div className={`w-full pt-[72px] bg-light-theme dark:bg-gray-900 relative ease-out transition-all duration-700 ${context.state.showSidebar && 'ml-[-300px] mr-[300px]'}`} style={{minHeight: `calc(100vh - 169px)`}}>
        {context.state.isLoading && <div style={{height: '150px'}}>Loading ...</div>}
        <Routes >
          <Route path="/" element={<Home />}/>
          <Route path='courses/:courseId' element={<CourseDetail/>} breadcrumb='courseDetail'/>
          <Route path="courses" element={<Courses />}/>
          <Route path="packages/:packageId" element={<PackageDetail />}/>
          <Route path="packages" element={<Packages />}/>
          <Route path="teacher/:teacherId" element={<TeacherProfile />}/>
          <Route path="mycourses/passed/:courseId" element={<MyPassedCourse />}/>
          <Route path="mycourses" element={<MyCourses />}/>
          <Route path="watchcourse/exam/:courseId" element={<TakeExamPage />}/>
          <Route path="watchcourse/:courseId" element={<WatchCourse />}/>
          <Route path="myaccount" element={<MyAccount />}/>
          <Route path="exams/:examId" element={<ExamDetail />}/>
          <Route path="exams" element={<Exams />}/>
          <Route path="myexams/detail/:examId" element={<MyExamDetail />}/>
          <Route path="myexams/:examId" element={<TakeExamPage />}/>
          <Route path="myexams" element={<MyExam />}/>
          <Route path="test" element={<Test/>} />
          <Route path="search" element={<CertificateSearch/>} />
          <Route path="checkcertificate/:certNumber" element={<ViewCertificate/>} />
          <Route
            path="*"
            element={
              <main className='flex justify-center items-center'>
                <p>404 Хуудас олдсонгүй</p>
              </main>
            }
          />
        </Routes>
      </div>
      <LoginModal show={context.state.showLoginModal}/>
      <PrivacyPolicyModal show={context.state.showPrivacyPolicy}/>
      <ConflictModal show={context.state.conflictModal}/>
      <Footer/>
    </>
  );
}

export default App;
