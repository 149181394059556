import { Disclosure } from '@headlessui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'moment-duration-format'
import Tooltip from 'components/Tooltip'

function ContentSidebar({handleChangeVideo, currentVideo, videoEnded, handleVideoEnded, handleTest, handleExamModal}) {
  const [ sections, setSections ] = useState([]) 
  const [ courseData, setCourseData ] = useState([]) 

  const {courseId} = useParams()

  useEffect(() => {
    getInitData()
    getCourseData()
  },[])

  useEffect(() => {
    if(videoEnded){
      axios({
        method: 'GET',
        url: `studentcoursesectionvideo/check/${currentVideo.id}`
      }).then((res) => {
        getData()
        getCourseData()
        handleVideoEnded(false)
      }).catch((err) => {
        console.log(err)
      })
    }
  },[videoEnded])

  const getInitData = () => {
    axios({
      method: 'GET',
      url: `mycourse/${courseId}/section`
    }).then((res) => {
      setSections(res.data)
      res.data.map((section, si) => {
        if(si == 0){
          section.course_section_videos.map((video, index) => {
            if(index == 0){
              handleChangeVideo(video)
            }
          })
        }
      })
    }).catch((err) => {
      console.log(err)
    })
  }

  const getCourseData = () => {
    axios({
      method:'GET',
      url:`mycourse/${courseId}`
    }).then((res) => {
      setCourseData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const getData = () => {
    axios({
      method: 'GET',
      url: `mycourse/${courseId}/section`
    }).then((res) => {
      setSections(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleViewStatus = (video, event) => {
    event.stopPropagation();
    if(video.viewStatus === 1){
      axios({
        method: 'GET',
        url: `studentcoursesectionvideo/uncheck/${video.id}`
      }).then((res) => {
        getData()
        getCourseData()
      }).catch((err) => {
        console.log(err)
      })
    }
    else{
      axios({
        method: 'GET',
        url: `studentcoursesectionvideo/check/${video.id}`
      }).then((res) => {
        getData()
        getCourseData()
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  return (
    <div className='md:fixed right-0 md:w-1/4 h-full bg-white shadow-sm z-10 border-t overflow-auto'>
      <div className='xs:hidden md:flex items-center bg-white'>
        <div className='relative px-4 py-2 bg-slate-300 w-full'>
          <div className='absolute top-0 right-0 bottom-0 left-0 bg-sky-400 z-0' style={{width: `${courseData.viewPercent}%`}}></div>
          <div className='relative flex justify-between text-slate-800'>
            <div>Хичээлийн агуулга</div>
            <div>{`${courseData.viewPercent}%`}</div>
          </div>        
        </div>
      </div>
      <div className='flex flex-col h-[calc(100vh-112px)] overflow-y-auto'>     
        {
          sections.map((section, i) => (
            <Disclosure key={i}>
              {
                ({open}) => (
                  <>
                    <Disclosure.Button className="flex justify-between items-center p-2 px-4 w-full font-medium text-left text-gray-900 bg-gray-100 border border-b-[0.5px] border-gray-200 dark:border-gray-700 dark:text-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800">
                      <div>
                        <div>{section.title}</div>
                        <div className='flex flex-wrap gap-3'>
                          {
                            section.course_section_videos.length != 0 &&
                            <div className='text-xs font-light flex items-center'>
                              <ion-icon name="videocam-outline"></ion-icon> 
                              <span className='ml-2'>Видео хичээл</span>
                              <span className='ml-1'>- {section.course_section_videos.length}</span>
                            </div>
                          }
                          {
                            section.course_tests.length != 0 &&
                            <div className='text-xs font-light flex items-center'>
                              <ion-icon name="document-outline"></ion-icon> 
                              <span className='ml-2'>Тест</span>
                              <span className='ml-1'>- {section.course_tests.length}</span>
                            </div>
                          }
                        </div>
                      </div>
                      <ion-icon name={`chevron-${open ? 'up': 'down'}-outline`}></ion-icon>
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500 border-x">
                      {
                        section.course_section_videos.map((video, i) => (
                          <Tooltip
                            tooltipContent={
                              <div>
                                { video.thumbnail && <img src={video.thumbnail.replace('jpg', 'gif')}/> }
                              </div>
                            }
                          >
                            <div>
                              <button 
                                key={i} 
                                className={`w-full text-left text-xs py-1.5 pl-4 flex flex-col hover:bg-sky-100 transition-all ${currentVideo ? currentVideo.id == video.id && 'bg-sky-100 font-semibold' : null}`}
                                onClick={() => handleChangeVideo(video)}
                              >
                                <div className='flex justify-between items-center w-full pr-3'>
                                  <div className='flex'>
                                    <input 
                                      type={'checkbox'} 
                                      checked={video.viewStatus ? true : false} 
                                      onClick={(e) => handleViewStatus(video, e)} 
                                      className='mr-3 w-[16px] h-[16px] cursor-pointer'
                                    />                                  
                                    <div className='flex'>
                                      <ion-icon name="play-circle" style={{fontSize: '18px'}}></ion-icon>
                                    </div>
                                    <span className='ml-2'>{video.title}</span>                                  
                                  </div>
                                  <div>{video.duration}</div>
                                </div>
                              </button>
                              <div className=' ml-11 text-xs'>
                                {video.course_section_video_resources.map((item, i) => (
                                  <div key={i} className='flex items-center'>
                                    { 
                                      item.resourceType == 'Файл' ? 
                                      <ion-icon name="document-outline" style={{fontSize: '16px'}}></ion-icon>
                                      :
                                      <ion-icon name="globe-outline" style={{fontSize: '16px'}}></ion-icon>
                                    }
                                    <a 
                                      className='ml-2 hover:underline' 
                                      href={item.resourceType && item.resourceType == 'Файл' ? `${process.env.REACT_APP_ASSET_URL}${item.url}` : item.url} 
                                      target='_blank'
                                    >
                                      {item.title}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Tooltip>
                        ))
                      }
                      {
                        section.course_tests.map((test, i) => (
                          <button 
                            key={i} 
                            className='w-full text-left text-xs py-1.5 pl-4 flex items-center hover:bg-sky-100 transition-all' 
                            onClick={() => handleTest(section.id)}
                          >
                            <div className='flex justify-between items-center w-full pr-3'>
                              <div className='flex'>
                                <input 
                                  type={'checkbox'} 
                                  checked={test.sectionTestStatus ? true : false} 
                                  // onClick={(e) => handleViewStatus(video, e)} 
                                  className='mr-3 w-[16px] h-[16px] cursor-pointer'
                                  />
                                <div className='flex'>
                                  <ion-icon name="reader-outline" style={{fontSize: '18px'}}></ion-icon>
                                </div>
                                <span className='ml-2'>{test.test}</span>
                              </div>
                              <div>{test.sectionTestScore}%</div>
                            </div>
                          </button>
                        ))
                      }
                    </Disclosure.Panel>
                  </>
                )
              }
            </Disclosure>    
          ))
        }
        {
          courseData.viewPercent > 80 && 
          <div className='flex justify-center p-2 px-4 w-full font-medium text-left text-gray-900 bg-gray-100 border border-b-[0.5px] border-gray-200 dark:border-gray-700 dark:text-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800'>
            <button 
              className='bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto'
              onClick={() => handleExamModal(true)}
            >
              ШАЛГАЛТ ӨГӨХ
            </button>
          </div>
        }
      </div>
      
    </div>
  )
}

export default ContentSidebar