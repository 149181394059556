import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { CgClose } from 'react-icons/cg'
import FormError from '../formError'
import * as Yup from 'yup'
import axios from 'axios'
import Policy from './policy'
function Signup({handleForm, handleSubmit, logo, handleModal}) {
  const [notMatch, setNotMatch ] = useState(false)
  const [ isAgreePrivacyPolicy, setIsAgreePrivacyPolicy ] = useState(false)
  const [ privacyPolicy, setPrivacyPolicy ] = useState(null)
  const [ showPrivacyPolicy, setShowPrivacyPolicy ] = useState(false)
  const [ showPassword, setShowPassword ] = useState(false)
  const [ showConfirmPassword, setShowConfirmPassword ] = useState(false)

  useEffect(() => {
    axios({
      method: 'GET',
      url: 'policy'
    }).then((res) => {
      setPrivacyPolicy(res.data.policy)
    }).catch((err) => {
      console.log(err)
    })
  },[])

  const validSchema = Yup.object().shape({
    email: Yup.string().email('Зөв имайл оруулна уу').max(255).required('Имэйл хаягаа оруулна уу'),
  });



  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
        policyCheck: ''
      }}
      validationSchema={validSchema}
      onSubmit={(values) => {
        if(values.password === values.confirmPassword){
          handleSubmit(values)
        }
        else{
          setNotMatch(true)
        }
      }}
    >
      {({
        handleChange,
        isSubmitting,
        handleSubmit,
        setFieldValue, values
      }) => {
        return(
          <>
            {
              !showPrivacyPolicy ?
              <Form action="#" className="flex flex-col items-center relative w-[25rem] bg-white py-4 px-6 rounded-md text-black dark:bg-gray-800">
                  <button className='absolute top-0 right-0 p-3' type='button' onClick={() => handleModal(false)}>
                    <CgClose size={25} className='text-sky-400'/>
                  </button>
                  <div style={{marginBottom: '20px'}} className="flex justify-center items-center mt-2">
                    <img src={logo} alt="logo" width={180}/>
                  </div>
                  <div className="mt-3 w-full">
                    <input 
                      name='email'
                      type="text" 
                      className="input-field" 
                      placeholder="И-Мэйл хаяг"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <ErrorMessage name='email' render={FormError}/>
                  </div>
                  <div className="mt-3 w-full flex relative">
                    <input 
                      name='password'
                      type={showPassword ? 'text' : 'password'} 
                      className="input-field pr-9" 
                      placeholder="Нууц үг" 
                      value={values.password}
                      onChange={handleChange}
                    />
                    {
                      values.password &&
                      <button type='button' className='flex justify-center items-center absolute right-0 top-0 bottom-0 m-2 p-1.5 hover:bg-slate-200 rounded-full' onClick={() => setShowPassword(!showPassword)}>
                        <ion-icon name={`eye-${showPassword ? '' : 'off-'}outline`}></ion-icon>
                      </button>
                    }
                  </div>
                  <div className="my-3 w-full flex relative">
                    <input 
                      name='confirmPassword'
                      type={showConfirmPassword ? 'text' : 'password'} 
                      className="input-field pr-9" 
                      placeholder="Нууц үгээ давтах" 
                      value={values.confirmPassword}
                      onChange={handleChange}
                    />
                    {
                      values.confirmPassword &&
                      <button type='button' className='flex justify-center items-center absolute right-0 top-0 bottom-0 m-2 p-1.5 hover:bg-slate-200 rounded-full' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        <ion-icon name={`eye-${showConfirmPassword ? '' : 'off-'}outline`}></ion-icon>
                      </button>
                    }
                    { 
                      notMatch &&
                      <div className='error mt-2 ml-2'>
                        Та нууц үгээ зөв давтан оруулна уу !
                      </div>
                    }
                  </div> 
                  <div className="self-start">
                    <input id="remember" name="policyCheck" type="checkbox" checked={values.policyCheck} onClick={(e) => setFieldValue('policyCheck', e.target.checked)}/>
                    <button className='text-sky-400 hover:text-sky-500 ml-3' onClick={() => setShowPrivacyPolicy(true)}>Үйлчилгээний нөхцөл</button>
                  </div>            
                  <button className="text-white bg-sky-400 hover:bg-sky-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 duration-150 my-3" type='submit'>Бүртгүүлэх</button>
                  <span className="text-gray-500 text-center my-4">Нэвтрэх хэсэг рүү <button className='text-sky-500' onClick={() => handleForm(false)}>Буцах</button></span>    
              </Form>
              :
              <Policy data={privacyPolicy} isChecked={values.policyCheck} finished={setShowPrivacyPolicy} setCheck={(e) => setFieldValue('policyCheck', e.target.checked)}/>
            }
          </>
        )
      }}
    </Formik>
  )
}

export default Signup
