import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function Packages() {
  const [data, setData ] = useState([])

  useEffect(() => {
    getData();
  },[])

  const getData = () => {
    axios({
      method: 'GET',
      url: 'package'
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  return (
    <div className='max-w-7xl mx-auto px-3'>
      <div className='py-6'>
        <h2 className='mt-0 font-medium dark:text-white'>Багцууд</h2>
        <div className='flex flex-wrap -mx-2'>
          {
            data.map((item, i) => (
              <div className='sm:w-1/2 md:w-1/4 px-2 my-4' key={i}>
                <div className='card p-0'>
                  <img src={`${process.env.REACT_APP_ASSET_URL}${item.image}`} width='100%'/>
                  <div className='p-3'>
                    <Link to={`/packages/${item.urlId}`} className='card-title'>{item.name}</Link>
                    <div className='font-medium'>{new Intl.NumberFormat().format(item.price)} ₮</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Packages