import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { ImgErrorEmployee } from 'components/NoImage';
import axios from 'axios';
import {MdOutlineEdit} from 'react-icons/md'
import PopupConfirm from 'components/PopupConfirm';

function QA({data, questions, refreshData}) {
  const [ questionValue, setQuestionValue ] = useState('')
  const [ selectedQuestion, setSelectedQuestion ] = useState(null)
  const [ editQuestion, setEditQuestion ] = useState(null)
  const [ showEditQuestionInput, setShowEditQuestionInput ] = useState(false)
  const [ answer, setAnswer ] = useState([])

  useEffect(() => {
    if(selectedQuestion){
      getAnswer()
    }
  },[selectedQuestion])
  
  const getAnswer = () => {
    axios({
      method:'GET',
      url: `coursequestion/${selectedQuestion.id}`
    }).then((res) => {
      setAnswer(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  const submitQuestion = () => {
    axios({
      method: 'POST',
      url: 'coursequestion',
      data: {
        question: questionValue,
        courseId: data.id
      }
    }).then((res) => {
      setQuestionValue('')
      refreshData()
    }).catch((err) => {
      console.log(err)
    })
  }

  const submitDeleteQuestion = (question) => {
    axios({
      method: 'DELETE',
      url: `coursequestion/${question.id}`
    }).then((res) => {
      refreshData()
    }).catch((err) => {
      console.log(err)
    })
  }

  const submitEditQuestion = () => {
    axios({
      method: 'PUT',
      url: `coursequestion`,
      data: {
        question: editQuestion,
        courseId: data.id,
        id: selectedQuestion.id
      }
    }).then((res) => {
      setShowEditQuestionInput(false)
      refreshData()
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleEditBtn = (question) => {
    setSelectedQuestion(question)
    setEditQuestion(question.question)
    setShowEditQuestionInput(!showEditQuestionInput)
  }
  
  const handleCancelQuestionEdit = () => {
    setEditQuestion(null)
    setShowEditQuestionInput(false)

  }
  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className='text-xl mb-3' >Асуулт Хариулт</div>
      </div>
      <div className=''>
        {questions.map((question, i) => (
          <div className='flex mb-2 items-start gap-4 ' key={i}>
            <img 
              className='w-10 rounded-full'
              src={`${process.env.REACT_APP_ASSET_URL}${question.image}`}
              onError={(e) => {e.target.error=null; e.target.src = ImgErrorEmployee}}
            />
            <div className='text-sm w-full'>
              <div className='flex items-center'>
                <div className='mr-2 text-gray-600'>{question.lastName} {question.firstName}&nbsp; • &nbsp;{moment(question.createdAt).format('YYYY-MM-DD hh:mm')}</div>
                {
                  question.myQuestion == 1 &&
                  <>
                    <PopupConfirm onConfirm={() => submitDeleteQuestion(question)} key={i} title='Та уг асуултыг устгах уу?'>
                      <button className='text-red-600 p-1 rounded hover:bg-red-100 flex'><ion-icon name="trash-outline"></ion-icon></button>
                    </PopupConfirm>
                    <button className='text-slate-600 p-1 rounded hover:bg-red-100 flex' onClick={() => handleEditBtn(question)}>
                      <MdOutlineEdit/>
                    </button>
                  </>
                }
              </div>
              {
                showEditQuestionInput && selectedQuestion.id == question.id ?
                <div>
                  <textarea id="message" rows="4" className="block p-2.5 w-full text-sm bg-gray-50 rounded-lg border focus:outline-none focus:border-blue-300 resize-none border-gray-300  dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="Асуултаа бичих..." value={editQuestion} onChange={(e) => setEditQuestion(e.target.value)}/>
                  <button onClick={submitEditQuestion} className='text-sm p-2 my-2 bg-sky-500 text-white rounded mr-2'>
                    Хадгалах
                  </button>
                  <button onClick={() => handleCancelQuestionEdit()} className='text-sm p-2 my-2 bg-blue-100 rounded text-gray-600'>
                    Цуцлах
                  </button>
                </div>
                :
                <div>{question.question}</div>
              }
              {
                answer && answer.id == question.id ?
                answer.course_question_answers.map((answer, i) => (
                  <div key={i} className='mt-3 flex gap-4'>
                    <img 
                      className='w-10 rounded-full'
                      src={`${process.env.REACT_APP_ASSET_URL}${answer.image}`}
                      onError={(e) => {e.target.error=null; e.target.src = ImgErrorEmployee}}
                      />
                    <div  className='flex flex-col'>
                      <div className='mr-2 text-gray-700'>{answer.lastname} {answer.firstname}&nbsp; • &nbsp;{moment(answer.createdAt).format('YYYY-MM-DD hh:mm')}</div>
                      <div  dangerouslySetInnerHTML={{__html: answer.answer}}/>
                    </div>
                  </div>   
                ))
                :
                question.answerCount !== 0 ? 
                <button className='text-blue-500 underline mt-1' onClick={() => setSelectedQuestion(question)}>Хариулт ({question.answerCount}) </button>
                :
                null
              }
            </div>
          </div>
        ))}       
      </div>
      <div className='mt-5'>
        {/* <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Таны асуулт</label> */}
        <textarea 
          id="message" 
          rows="4" 
          className="p-2.5 w-full text-sm  bg-gray-50 rounded-lg border focus:outline-none focus:border-blue-300 resize-none border-gray-300  dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " 
          placeholder="Асуултаа бичих..." 
          value={questionValue} 
          onChange={(e) => setQuestionValue(e.target.value)}
        />
        <button onClick={submitQuestion} className='text-sm p-2 my-2 bg-sky-500 text-white rounded mr-2'>
          Нийтлэх
        </button>
      </div>
    </div>
  )
}

export default QA