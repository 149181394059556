import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoStar, IoPeople } from "react-icons/io5";
import axios from 'axios';
import { SystemContext } from 'utils/context';
import PaymentModal from 'components/PaymentModal';

function PackageDetail() {
  const [ data, setData ] = useState(null)
  const [ courses, setCourses ] = useState([])
  const [ qrData, setQrData ] = useState(null)
  const { packageId } = useParams() 
  const context = useContext(SystemContext)

  useEffect(() => {
    getPackages();
  },[])
  
  useEffect(() => {
    getPackages();
    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
  },[packageId])
  
  const getPackages = () => {
    axios({
      method: 'GET',
      url: `package/${packageId}`
    }).then((res) => {
      setData(res.data)
      setCourses(res.data.courseData)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleBuyBtn = () => {
    axios({
      method: 'POST',
      url: 'invoice/package',
      data: {urlId: packageId}
    }).then((res) => {
      setQrData(res.data)
      context.action.handlePaymentModal(true)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className='max-w-7xl mx-auto px-3'>
      <div className='py-6'>
        <PaymentModal show={context.state.showPaymentModal} qrData={qrData} detailData={data && data.data}/>
        {
          data &&
          <>
            <h2 className='mt-0 mb-6 font-medium'>Багцын сургалтууд</h2>
            <div className='flex xs:flex-col md:flex-row gap-7'>
              <div className='flex flex-col md:w-2/3 xs:w-full gap-7'>              
                {
                  courses.map((course, index) => (
                    <div className='course-list-card' key={index}>
                      <div className='w-2/5 flex'>
                        <img src={`${process.env.REACT_APP_ASSET_URL}${course.image}`} width='100%' height='100%' className='object-cover object-center'/>
                      </div>
                      <div className='w-3/5 p-3'>
                        <div>
                          <Link to={`/courses/${course.urlId}`} className='card-title line-clamp-2' >{course.title}</Link>
                          <div className='flex my-3 items-center'>
                            <div className='pr-2 flex items-center'>
                              <IoStar name="star" style={{color: 'gold'}}/> 
                              <span className='ml-1 text-slate-400 font-medium'>{parseFloat(course.avg_rating).toFixed(1)}</span>
                            </div>
                            <div className='px-2 text-sm font-medium'>{course.topicNames}</div>
                          </div>
                          <div className='text-gray-500 text-sm'>What Will I Learn? Improve your productivity, get things done, and find more time for what's most… What Will I Learn? Improve your productivity, and for what's most… </div>
                        </div>
                        <div className='flex justify-between items-center p-2'>
                          <div className='flex items-center text-slate-400'>
                            <IoPeople name="people" ></IoPeople>
                            <div className='ml-2'>63</div>
                          </div>
                          <div className='font-medium'>{course.price} ₮</div>
                        </div>
                      </div>
                    </div>
                  ))
                }      
              </div>

              {/* -----------------------  SIDEBAR  ------------------------ */}

              <div className='flex flex-col md:w-1/3 xs:w-full gap-7'>
                <div className='card flex flex-col gap-3'>
                  <div className='font-medium text-lg'>{data.data.name}</div>
                  <img src={`${process.env.REACT_APP_ASSET_URL}${data.data.image}`}/>
                  <div className='text-sm'>{data.data.descr}</div>
                  <div>Сургалтын үнэ: <b>{Intl.NumberFormat().format(data.data.price)} ₮</b></div>
                  {
                    !data.data?.status ?
                    <button className='flex justify-center items-center bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto mt-4' onClick={handleBuyBtn}>
                      <ion-icon name="cart-outline" style={{fontSize:'18px'}}/>
                      <span className='ml-2' >ХУДАЛДАЖ АВАХ</span>
                    </button>
                    :
                    data.data.status === 'Үүссэн' ?
                    <button className='flex justify-center items-center bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto mt-4' onClick={handleBuyBtn}>
                      <ion-icon name="card-outline" style={{fontSize:'18px'}}/>
                      <span className='ml-2' >ТӨЛӨХ</span>
                    </button>
                    :
                    data.data.status === 'Төлөгдсөн' ?
                    <button className='flex justify-center items-center bg-blue-200 text-blue-600 hover:bg-blue-300 duration-150 px-4 py-2 rounded-md text-sm font-medium mx-auto mt-4'> 
                      <span className='ml-2'>БАГЦ АВСАН</span>
                    </button>
                    : null
                  }
                </div>
                { data.suggestPackage.length > 0 &&
                  <div className='card flex flex-col'>
                    <h3 className='font-normal mt-0'>Санал болгох багц</h3>
                    <ul className='divide-y dark:divide-slate-700'>
                      {
                        data.suggestPackage.map((item, i) => (
                          <li key={i} className='flex justify-between items-center py-2'>
                            <Link to={`/packages/${item.urlId}`} className='py-2'>{item.name}</Link>
                            <span>{new Intl.NumberFormat().format(item.price)} ₮</span>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                }
                { data.suggestCourse.length > 0 &&
                  <div className='card flex flex-col'>
                    <h3 className='font-normal mt-0'>Санал болгох сургалт</h3>
                    <ul className='divide-y dark:divide-slate-700'>
                      {
                        data.suggestCourse.map((item, i) => (
                          <li className='py-2 flex gap-2' key={i}>
                            <div className='w-[30%]'>
                              <img src={`${process.env.REACT_APP_ASSET_URL}${item.image}`} ></img>
                            </div>
                            <div className='w-[70%]'>
                              <Link className='font-medium text-sm line-clamp-3' to={`/courses/${item.urlId}`}>{item.title}</Link>

                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                }              
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default PackageDetail