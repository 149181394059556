import React, { useEffect, useRef, useState } from 'react'

function PopupConfirm({children, title, onConfirm, onCancel, confirmText, cancelText, icon, modalStatus}) {
  const [ show, setShow ] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
          setShow(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  },[ref])

  const handleConfirm = async () => {
    await  onConfirm();
    setShow(false);
  };

  return (
    <div>
      <div className={`relative inline-block text-left`}>
        <div aria-haspopup='true' aria-expanded='true' onClick={() => setShow(!show)}>
          {children}
        </div>
        {
          show && children  &&
          <div ref={ref} className="absolute  bottom-[120%] z-10 mt-2 rounded-md shadow-no3 bg-white dark:bg-gray-800 flex w-[250px] px-4 py-3" role="menu" aria-orientation="vertical">
            <div className='flex'>
              <ion-icon name="alert-circle" style={{fontSize: '20px', color: 'orange'}}></ion-icon>
            </div>
            <div className='flex flex-col gap-2'>
              <div>{title}</div>
              <div className='flex justify-end gap-2 text-xs'>
                <button className='bg-sky-500 text-white px-3 py-0.5 rounded' onClick={() => handleConfirm()}>OK</button>
                <button className='border rounded px-3 py-0.5' onClick={() => setShow(false)}>CANCEL</button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default PopupConfirm