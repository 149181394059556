import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function MyExam() {
  const [ data, setData ] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    getData();
  },[])

  const getData = () => {
    axios({
      method: 'GET',
      url: 'myexam'
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className='max-w-7xl mx-auto px-3'>
      <h2 className='text-slate-600'>Mиний шалгалт</h2>
      <div className='flex flex-wrap -mx-3'>
        {
          data.map((course,i) => (
            <div key={i} className='p-3 lg:w-1/4 md:w-1/3 sm:w-1/2 cursor-pointer' onClick={() => navigate(`${course.status == 'Төгссөн' ? '../myexams/detail/' : '../exams/'}${course.urlId}`)}>
              <div className='card p-0'>
                <div className='relative group' >
                  <img src={`${process.env.REACT_APP_ASSET_URL}${course.image}`}/>
                  <img 
                    src='https://www.udemy.com/staticx/udemy/js/webpack/icon-play.ac3f32ecb72a0c3f674fa5a3f3062a56.svg' 
                    width={80} 
                    className='absolute top-0 bottom-0 right-0 left-0 m-auto scale-50 opacity-0 group-hover:opacity-100 group-hover:scale-100 transition-all'
                  />
                </div>
                <div className='p-3'>
                  <div className='text-sm line-clamp-2 font-medium hover:text-sky-500'>{course.title}</div>
                  <div className='text-[13px] text-gray-600 flex flex-wrap line-clamp-2'>
                    {
                      course.topicNames.split(',').map((item, i) => (
                        <span key={i}>{i != 0 && ` • `} {item}</span>
                      ))
                    }
                  </div>
                  <div className='flex flex-col my-2'>
                    <div className='text-[13px] font-medium'>{`${Intl.NumberFormat().format(course.price)}`} ₮</div>
                  </div>
                  <div className='flex justify-between'>
                    <div className='text-xs'>Дуусах хугацаа: {moment(course.expireDate).format('YYYY-MM-DD')}</div>
                    <div className='text-xs'>{course.status}</div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }    
      </div>
    </div>
  )
}

export default MyExam