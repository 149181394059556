import axios from 'axios'
import CustomForm from 'components/CustomForm'
import Modal from 'components/Modal'
import { ImgErrorEmployee } from 'components/NoImage'
import React, { useEffect, useState, Fragment, useContext } from 'react'
import { Tab } from '@headlessui/react'
import moment from 'moment'
import ConfirmModal from 'components/ConfirmModal'
import PaymentModal from 'components/PaymentModal'
import { SystemContext } from 'utils/context'

function MyAccount() {
  const [ data, setData ] = useState(null)
  const [ showModal, setShowModal ] = useState(false)
  const [ showPictureModal, setShowPictureModal ] = useState(false)
  const [ showDeleteModal, setShowDeleteModal ] = useState(false)
  const [ invoiceData, setInvoiceData ] = useState(null)
  const [ selectedInvoice, setSelectedInvoice ] = useState(null)
  const [ qrData, setQrData ] = useState(null)
  const [ deviceLog, setDeviceLog ] = useState([])

  const context = useContext(SystemContext)

  useEffect(() => {
    getData()
    getInvoiceData()
    getDeviceLog()
  },[])

  const getData = () => {
    axios({
      method: 'GET',
      url: 'profile'
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const getInvoiceData = () => {
    axios({
      method: 'GET',
      url: 'invoice'
    }).then((res) => {
      setInvoiceData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  
  const getDeviceLog = () => {
    axios({
      method: 'GET',
      url: 'studentdevice'
    }).then((res) => {
      setDeviceLog(res.data)
    }).catch((err) => {
      console.log(err)
    })
  } 
  const columns = [
    {
      title: 'Овог',
      dataIndex: 'lastName',
      type: "text",
    },
    {
      title: 'Нэр',
      dataIndex: 'firstName',
      type: "text",
    },
    {
      title: 'И-Мэйл',
      dataIndex: 'email',
      type: "text",
      
    },
    {
      title: 'Утас',
      dataIndex: 'mobile',
      type: "text",
    },
  ]

  const pictureColumn = [
    {
      title: 'Зураг',
      dataIndex: 'image', 
      type: 'cropImg',
      aspect: 1/1
    }
  ]

  const handleSubmit = (values, resetForm) => {
    axios({
      method: 'PUT',
      url: 'profile',
      data: {
        ...values
      }
    }).then((res) => {
      context.action.changeProfileInfo(values)
      getData();
      setShowModal(false);
      resetForm();
    }).catch((err) => {
      console.log(err)
    })
  }
  
  const handlePictureSubmit = (values, resetForm) => {
    const formData = new FormData();
    formData.append('image', values.image)
    axios({
      method: 'PUT',
      url: 'profile/changeimage',
      data: formData,
      headers: {"Content-Type": "multipart/form-data"}
    }).then((res) => {
      getData();
      setShowPictureModal(false);
      resetForm();
    }).catch((err) => {
      console.log(err)
    })
  }

  const handlePayRequest = (invoice) => {
    axios({
      method: 'PUT',
      url: 'invoice/payrequest',
      data: {
        id: invoice.id
      }
    }).then((res) => {
      let tmp = {...res.data, ...invoice}
      setQrData(tmp)
      context.action.handlePaymentModal(true)
    }).catch((err) => {
      console.log(err)
    })
  } 

  const handleDeleteInvoice = () => {
    axios({
      method: 'DELETE',
      url: `invoice/${selectedInvoice.id}`
    }).then((res) => {
      setSelectedInvoice(null)
      setShowDeleteModal(false)
      getInvoiceData()
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleDeleteBtn = (invoice) => {
    setSelectedInvoice(invoice)
    setShowDeleteModal(true)
  }

  const handlePayBtn = async(invoice, type) => {
    setSelectedInvoice(invoice)
    handlePayRequest(invoice)
  }
  return (
    <div className='max-w-7xl mx-auto px-3 py-8'>
      {
        data &&
        <div className='card flex flex-col gap-9'>
          <div className='w-full'>
            <div className='flex gap-8 items-start' >
              <div className='relative'>
                <img 
                  src={`${process.env.REACT_APP_ASSET_URL}${data.image}`} 
                  onError={(e) => {e.target.onError=null; e.target.src=ImgErrorEmployee} }
                  className='w-[200px] rounded-full '
                />
                <button className='p-2 bg-slate-200 flex absolute bottom-0 right-5 rounded-full hover:shadow-no4 transition-all text-gray-400' onClick={() => setShowPictureModal(true)}>
                  <ion-icon name="camera-outline" style={{fontSize:'25px'}}></ion-icon>
                </button>
              </div>
              <div className='flex flex-col gap-2'>
                <div>Овог: {data.lastName}</div>
                <div>Нэр: {data.firstName}</div>
                <div>И-Мэйл: {data.email}</div>
                <div>Утас: {data.mobile}</div>
                <div>
                  Үйлчилгээний нөхцөл: 
                  { 
                    data.policyCheck == 1 ? 
                    <span className='bg-green-100 ml-3 text-green-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900'>
                      Зөвшөөрсөн 
                    </span> : 
                    <span className='bg-red-100 ml-3 text-red-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900'>
                      Зөвшөөрөөгүй
                    </span>
                  }
                </div>
                <button className='self-end bg-sky-300 text-blue-900 font-medium rounded py-1 px-4 mt-4' onClick={() => setShowModal(true)}>Засах</button>
              </div>                
            </div>
          </div>
          <div>
            <Tab.Group>          
              <Tab.List className={'flex overflow-x-auto -mb-px text-sm text-center text-gray-500 dark:text-gray-400 border-b-2 bg-white'}>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button className={`tab-btn rounded-none ${selected && 'dark:text-blue-500 text-blue-600 border-blue-600 hover:border-blue-600 hover:text-blue-600 active dark:border-blue-500 whitespace-nowrap'}`} role='tab'>
                    Нэхэмжлэл 
                  </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button className={`tab-btn rounded-none ${selected && 'dark:text-blue-500 text-blue-600 border-blue-600 hover:border-blue-600 hover:text-blue-600 active dark:border-blue-500 whitespace-nowrap'}`} role='tab'>
                    Гэрчилгээ 
                  </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button className={`tab-btn rounded-none ${selected && 'dark:text-blue-500 text-blue-600 border-blue-600 hover:border-blue-600 hover:text-blue-600 active dark:border-blue-500 whitespace-nowrap'}`} role='tab'>
                    Нэвтрэлтийн түүх 
                  </button>
                  )}
                </Tab>
              </Tab.List>
              <Tab.Panels className='max-w-7xl mx-auto px-3 py-5 overflow-x-auto'>  
                <Tab.Panel>
                  {
                    invoiceData &&
                    <div className='text-sm'>
                      {
                        invoiceData.content.length > 0 && 
                        <div className='mb-3'>
                          <div className='mb-1 font-medium text-sky-500'>КУРС ХИЧЭЭЛ</div>
                          <table className='border' >
                            <thead className='border-b'>
                              <tr className='font-medium text-slate-600 bg-gray-50'>
                                <td className='px-2 w-[200px]'>Нэхэмжлэлийн дугаар</td>
                                <td className='px-2 w-[300px]'>Нэр</td>
                                <td className='px-2 w-[100px]'>Үнэ</td>
                                <td className='px-2 w-[100px]'>Огноо</td>
                                <td className='px-2 w-[120px]'>Төлөв</td>
                                <td className='px-2 w-[120px]'></td>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                invoiceData.content.map((invoice, i) => (
                                  <tr className='border-b p-1 ' key={i}>
                                    <td className='px-2 py-1'>{invoice.invoiceNo}</td>
                                    <td className='px-2 py-1 leading-tight'>{invoice.title}</td>
                                    <td className='px-2 py-1'>{Intl.NumberFormat().format(invoice.price)} ₮</td>
                                    <td className='px-2 py-1'>{moment(invoice.createdAt).format('YYYY-MM-DD')}</td>
                                    <td className='px-2 py-1'>
                                      <div className={`text-xs inline-block py-1 px-2.5 leading-none text-center align-baseline font-bold ${invoice.status == 'Үүссэн' ? 'bg-blue-100 text-blue-500' : 'bg-green-200 text-green-500'} text-white rounded`}>
                                        {invoice.status}
                                      </div>
                                    </td>
                                    {invoice.status !== 'Төлөгдсөн' &&
                                      <td className='px-2 py-1'>
                                        <div className='flex flex-col gap-2 items-center'>
                                          <button 
                                            className='text-sm bg-sky-500 rounded text-white hover:bg-sky-400 px-2 transition-all' 
                                            onClick={() => handlePayBtn(invoice)}
                                          >
                                            Төлөх
                                          </button>
                                          <button 
                                            className='text-sm bg-red-400 rounded text-white hover:bg-red-300 px-2 transition-all' 
                                            onClick={() => handleDeleteBtn(invoice)}
                                          >
                                            Цуцлах
                                          </button>
                                        </div>
                                      </td>
                                    }
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      }
                      {
                        invoiceData.exeamData.length > 0 && 
                        <div>
                          <div className='mb-1 font-medium text-sky-500'>ШАЛГАЛТ</div>
                          <table className='border'>
                            <thead className='border-b'>
                              <tr className='font-medium text-slate-600 bg-gray-50'>
                                <td className='px-2 w-[200px]'>Нэхэмжлэлийн дугаар</td>
                                <td className='px-2 w-[300px]'>Нэр</td>
                                <td className='px-2 w-[100px]'>Үнэ</td>
                                <td className='px-2 w-[100px]'>Огноо</td>
                                <td className='px-2 w-[120px]'>Төлөв</td>
                                <td className='px-2 w-[120px]'></td>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                invoiceData.exeamData.map((exam, i) => (
                                  <tr className='border-b p-1 ' key={i}>
                                    <td className='px-2 py-1'>{exam.invoiceNo}</td>
                                    <td className='px-2 py-1 leading-tight'>{exam.title}</td>
                                    <td className='px-2 py-1'>{Intl.NumberFormat().format(exam.price)}</td>
                                    <td className='px-2 py-1'>{moment(exam.createdAt).format('YYYY-MM-DD')}</td>
                                    <td className='px-2 py-1'>
                                      <div className={`text-xs inline-block py-1 px-2.5 leading-none text-center align-baseline font-bold ${exam.status == 'Үүссэн' ? 'bg-blue-100 text-blue-500' : 'bg-green-200 text-green-500'} text-white rounded`}>
                                        {exam.status}
                                      </div>
                                    </td>
                                    {exam.status !== 'Төлөгдсөн' &&
                                      <td className='px-2 py-1'>
                                        <div className='flex flex-col gap-2 items-center'>
                                          <button 
                                            className='text-sm bg-sky-500 rounded text-white hover:bg-sky-400 px-2 transition-all' 
                                            onClick={() => handlePayBtn(exam)}
                                          >
                                            Төлөх
                                          </button>
                                          <button 
                                            className='text-sm bg-red-400 rounded text-white hover:bg-red-300 px-2 transition-all' 
                                            onClick={() => handleDeleteBtn(exam)}
                                          >
                                            Цуцлах
                                          </button>
                                        </div>
                                      </td>
                                    }
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      }
                      {
                        invoiceData.package.length > 0 && 
                        <div>
                          <div className='mb-1 font-medium text-sky-500'>БАГЦ</div>
                          <table className='border'>
                            <thead className='border-b'>
                              <tr className='font-medium text-slate-600 bg-gray-50'>
                                <td className='px-2 w-[200px]'>Нэхэмжлэлийн дугаар</td>
                                <td className='px-2 w-[300px]'>Нэр</td>
                                <td className='px-2 w-[100px]'>Үнэ</td>
                                <td className='px-2 w-[100px]'>Огноо</td>
                                <td className='px-2 w-[120px]'>Төлөв</td>
                                <td className='px-2 w-[120px]'></td>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                invoiceData.package.map((item, i) => (
                                  <tr className='border-b p-1' key={i}>
                                    <td className='px-2 py-1'>{item.invoiceNo}</td>
                                    <td className='px-2 py-1 leading-tight'>{item.title}</td>
                                    <td className='px-2 py-1'>{Intl.NumberFormat().format(item.price)}</td>
                                    <td className='px-2 py-1'>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                                    <td className='px-2 py-1'>
                                      <div className={`text-xs inline-block py-1 px-2.5 leading-none text-center align-baseline font-bold ${item.status == 'Үүссэн' ? 'bg-blue-100 text-blue-500' : 'bg-green-200 text-green-500'} text-white rounded`}>
                                        {item.status}
                                      </div>
                                    </td>
                                    {item.status !== 'Төлөгдсөн' &&
                                      <td className='px-2 py-1'>
                                        <div className='flex flex-col gap-2 items-center'>
                                        <button 
                                            className='text-sm bg-sky-500 rounded text-white hover:bg-sky-400 px-2 transition-all' 
                                            onClick={() => handlePayBtn(item)}
                                          >
                                            Төлөх
                                          </button>
                                          <button 
                                            className='text-sm bg-red-400 rounded text-white hover:bg-red-300 px-2 transition-all' 
                                            onClick={() => handleDeleteBtn(item)}
                                          >
                                            Цуцлах
                                          </button>
                                        </div>
                                      </td>
                                    }
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      }
                    </div>            
                  }      
                </Tab.Panel> 
                <Tab.Panel>
                  {
                    <div className='divide-y'> 
                      {
                        data.student_courses.map((item, i) => (
                          <div key={i} className='flex flex-col md:flex-row gap-3 py-3'>
                            <iframe height={'auto'} width='105px' src={`${process.env.REACT_APP_ASSET_URL}${item.certifcate}#view=FitH`}/>
                            <div className='flex flex-col items-start'>
                              <div className='font-medium'>{item.title}</div>
                              <div className='text-xs inline-block py-1 px-2.5 leading-none text-center align-baseline font-bold bg-blue-600 text-white rounded'>
                                {item.type}
                              </div>
                            </div>                            
                          </div>
                        ))
                      }
                    </div>            
                  }      
                </Tab.Panel> 
                <Tab.Panel>
                  <table className='border text-sm'>
                    <thead className='border-b'>
                      <tr className='font-medium text-slate-600 bg-gray-50'>
                        <td>IP</td>
                        <td className='w-[100px]'>Веб хөтөч</td>
                        <td>Хаанаас</td>
                        <td className='w-[100px]'>Төхөөрөмж</td>
                        <td>Хэзээ</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        deviceLog.map((item, i) => (
                          <tr className='border-b' key={i}>
                            <td className='pr-2 py-1'>{item.ipaddress}</td>
                            <td className='pr-2 py-1'>{item.browser}</td>
                            <td className='pr-2 py-1'>{item.city}, {item.country}</td>
                            <td className='pr-2 py-1'>{item.device}</td>
                            <td className='pr-2 py-1'>{moment(item.date).format('YYYY/MM/DD') }</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </Tab.Panel> 
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      }
      <Modal show={showModal} toggleShow={setShowModal} title='Мэдээлэл засах' className={'w-[400px]'}>
        <CustomForm fields={columns} closeModal={() => setShowModal(false)} editData={data} onSubmit={handleSubmit}/>
      </Modal>
      <Modal show={showPictureModal} toggleShow={setShowPictureModal} title='Зураг солих'>
        <CustomForm fields={pictureColumn} closeModal={() => setShowPictureModal(false)} editData={data} onSubmit={handlePictureSubmit}/>
      </Modal>
      <ConfirmModal show={showDeleteModal} showToggle={setShowDeleteModal} title='Та уг нэмэмжлэлийг устгахдаа итгэлтэй байна уу?' okText='Тийм' handleOk={handleDeleteInvoice}/>
      <PaymentModal show={context.state.showPaymentModal} qrData={qrData} detailData={selectedInvoice}/>
    </div>
  )
}

export default MyAccount