import axios from 'axios';
import React, { useEffect, useState } from 'react'
function Footer() {
  const [ data, setData ] = useState(null)

  useEffect(() => {
    getData();
  },[])

  const getData = () => {
    axios({
      method: 'GET',
      url: 'home'
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  return (
    <div className='w-full overflow-hidden pt-10 divide-y divide-slate-600 text-slate-300' style={{backgroundColor: '#2e3e77'}}>
      {
        data &&
        <>
          <div className='max-w-7xl mx-auto pb-4 flex flex-wrap'>
            <div className='px-3 md:w-1/3 sm:w-1/2'>
              {/* <h3 className='text-white'>Бидний тухай</h3> */}
              {/* <div className='pb-2 truncate line-clamp-3' dangerouslySetInnerHTML={{__html: data.organizationData.about}}></div> */}
              {
                data.organizationData &&
                <ul>
                  <li className='flex items-center py-1'>
                    <ion-icon name="mail-outline"></ion-icon>
                    <a href={`mailto:${data.organizationData.email}?Subject=ABC&Body=Body`} className='ml-2'>{data.organizationData.email}</a>
                  </li>
                  <li className='flex items-center py-1'>
                    <ion-icon name="call-outline"></ion-icon>
                    <a href={`tel:${data.organizationData.mobile}`} className='ml-2'>{data.organizationData.mobile}</a>
                  </li>
                </ul>
              }
            </div>
            {/* <div className='px-3 md:w-1/3 sm:w-1/2'>
              <h3 className='text-white'>Quick Links</h3>
              <div>
              We named our theme Studiare because to us,
              the best brands are simple ones. Brands thrive
              on their ability to be understood.
              </div>
              <ul>
                <li className='flex items-center  py-2'>
                  <IoLocationOutline name="location-outline"></IoLocationOutline>
                  <div className='ml-2'>127 Elizabeth Street, NY New York</div>
                </li>
                <li className='flex items-center'>
                  <IoCallOutline name="location-outline"></IoCallOutline>
                  <div className='ml-2'>127 Elizabeth Street, NY New York</div>
                </li>

              </ul>
            </div>
            <div className='px-3 md:w-1/3 sm:w-1/2'>
              <h3 className='text-white'>Newsletter</h3>
              <div>
                Don’t miss anything, sign up now and keep informed about our company.
              </div>
              <ul>
                <li className='flex items-center'>
                  <IoLocationOutline name="location-outline"></IoLocationOutline>
                  <div className='ml-2'>127 Elizabeth Street, NY New York</div>
                </li>
                <li className='flex items-center'>
                  <IoCallOutline name="call-outline"></IoCallOutline>
                  <div className='ml-2'>127 Elizabeth Street, NY New York</div>
                </li>

              </ul>
            </div> */}
            
          </div>
          <div className='max-w-7xl mx-auto py-4'>
            <div className='flex justify-between px-3 text-xs'>
              <div>© 2022 Fractal. Created by Fractal Technologies.</div>
              {/* <div>
                <ion-icon name="logo-google" className='mr-2'></ion-icon>
                <ion-icon name="logo-facebook"></ion-icon>
              </div> */}
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Footer