import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import ContentSidebar from './contentSidebar'
import Details from './details'
import CustomPlayer from 'components/CustomPlayer'
import Modal from 'components/Modal'
import Test from './components/test'

function WatchCourse() {
  const [ data, setData ] = useState(null)
  const [ sections, setSections ] = useState([])
  const [ currentVideo, setCurrentVideo ] = useState(null)
  const [ videoEnded, setVideoEnded ] = useState(false)
  const [ testData, setTestData ] = useState([])
  const [ isTest, setIsTest ] = useState(false)
  const [ testId, setTestId ] = useState(null)
  const [ showModal, setShowModal ] = useState(false)

  const {courseId} = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    getData()
  },[])

  const getData = () => {
    axios({
      method:'GET',
      url:`mycourse/${courseId}`
    }).then( async(res) => {
      await setSections(res.data.courseSection)
      await setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleTest = (id) => {
    setTestId(id)
    axios({
      method:'GET',
      url:`coursesectiontest/${id}`
    }).then( async(res) => {
      await setTestData(res.data)
      await setIsTest(true)
    }).catch((err) => {
      console.log(err)
    })
  }
  
  const handleSubmitTest = (score) => {
    axios({
      method:'POST',
      url:`coursesectiontest/${testId}`,
      data: {
        score: score
      }
    }).then((res) => {
      setTimeout(() => {
        setIsTest(false)
      },[5000])
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div>
      {
        data &&
        <div className='flex'>
          <div className='w-full md:w-3/4'>         
            <CustomPlayer src={currentVideo && currentVideo.fileURL} duration={currentVideo && currentVideo.duration} thumbnailImg={currentVideo && currentVideo.thumbnail} onEnded={setVideoEnded}/>            
            <Details data={data} refresh={getData}/>
          </div>
          <div className='xs:hidden md:block'>
            <ContentSidebar 
              handleChangeVideo={setCurrentVideo} 
              currentVideo={currentVideo} 
              videoEnded={videoEnded} 
              handleVideoEnded={setVideoEnded} 
              handleTest={handleTest}
              handleExamModal={setShowModal}
            />
          </div>  
          <Modal show={isTest} toggleShow={setIsTest} className='w-[300px] xl:w-[calc(50vw)]' title={'Шалгалт'}>
            <Test testData={testData} handleSubmit={handleSubmitTest} />
          </Modal>      
          <Modal show={showModal} toggleShow={setShowModal} className='w-[500px]' title={'Анхаарна уу!'}>
            <div>
              <div className='border bg-orange-100 text-slate-600 p-2'>
                Та <b>Эхлэх</b> товч дарснаар шалгалт шууд эхлэх бөгөөд та бэлэн болсныхоо дараа шалгалтыг эхлүүлээрэй. Таньд амжилт хүсье !
              </div>
              <div className='flex justify-end gap-2 mt-3'>
                <button className='bg-sky-500 text-white px-3 py-1 rounded-md hover:bg-sky-400 duration-200' onClick={() => navigate(`../watchcourse/exam/${courseId}`)}>Зөвшөөрөх</button>
                <button className='bg-gray-400 text-white px-3 py-1 rounded-md hover:bg-gray-300 duration-200' onClick={() => setShowModal(false)}>Татгалзах</button>
              </div>
            </div>
          </Modal>  
        </div>
      }
    </div>
  )
}

export default WatchCourse