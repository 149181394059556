import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { SystemContext } from 'utils/context'
import { IoSearch, IoPersonOutline, IoCartOutline } from 'react-icons/io5'

function Sidebar({navLinks, handleLogin}) {
  const context = useContext(SystemContext)

  return (
    <nav className={`fixed top-0 bottom-0 bg-slate-800 text-slate-200 py-3 px-3 w-[300px] right-[-300px] ease-out ${context.state.showSidebar && 'right-0'} transition-all duration-700 `}>
      <ul className='md:hidden space-y-4 text-sm px-4 py-3'>
        <div className='flex justify-between bg-slate-900 rounded-md'>
          <input className='px-3 py-2 w-full bg-slate-900 rounded-md text-slate-300' type={'search'}/>
          <button className='px-3 py-2'><IoSearch size={20}/></button>
        </div>
        <div className='flex flex-col p-1 bg-slate-600 rounded-md'>
        {
          context.state.userToken ?
          <>
            <div className='bg-slate-700 p-2 rounded-md mr-3'>
              <IoPersonOutline size={20}/> 
              <span className='ml-2'>
                {
                  (context.state.userInfo?.lastName && context.state.userInfo?.firstName) ? 
                  `${context.state.userInfo?.lastName} ${context.state.userInfo?.firstName}` : 
                  context.state.userInfo?.email
                }
              </span>
            </div>
            <div>
              <Link to="myaccount" onClick={() => context.action.handleSidebar(false)} className=" block px-2 py-2 text-sm" >
                Миний булан
              </Link>
              <Link to="mycourses" onClick={() => context.action.handleSidebar(false)} className="block px-2 py-2 text-sm" >
                Миний сургалт
              </Link>
              <Link to="myexams" onClick={() => context.action.handleSidebar(false)} className="block px-2 py-2 text-sm">
                Миний шалгалт
              </Link>
              <button 
                onClick={() => context.action.signOut()} 
                type="button" 
                className=" block w-full text-left px-2 py-2 text-sm dark:text-gray-200 hover:bg-sky-100" 
                role="menuitem" 
                tabIndex="-1" 
                id="menu-item-3"
              >
                Гарах
              </button>            
            </div>
          </>
          :
          <button className='bg-blue-500 px-3 py-1 rounded-md text-white' onClick={() => handleLogin()} >
            НЭВТРЭХ
          </button>
        }
        </div>
        { 
          navLinks.map((nav, i) => (
            <li key={i}>
              <NavLink
                className={(navData) => navData.isActive ? 'text-sky-500' : 'hover:text-sky-500'} 
                onClick={() => context.action.handleSidebar(false)}
                to={nav.link}
              >
                {nav.name}
              </NavLink>
            </li>
          ))
        }
      </ul>
    </nav>
  )
}

export default Sidebar