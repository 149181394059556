import axios from 'axios';
import GenerateStar from 'components/GenerateStars';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoPeople } from 'react-icons/io5'
import playIcon from'assets/play-overlay.png'

function TeacherProfile() {
  const [ data, setData ] = useState(null)
  const { teacherId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    getData();
  },[])

  const getData = () => {
    axios({
      url: `teacher/${teacherId}`,
      method: 'GET'
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  return (
    <div className='max-w-7xl mx-auto mt-8'>
      {
        data &&
        <>
          <div className='flex gap-5 items-start'>
            <div className='card w-1/3 flex flex-col items-center'>
              <img src={`${process.env.REACT_APP_ASSET_URL}${data.teacherProfile.image}`} width='100%'/>
              <div className='text-xl font-medium mt-2'>{data.teacherProfile.lastName} {data.teacherProfile.firstName}</div>
              <div className='text-gray-400'>{data.teacherProfile.position}</div>
              <div className='flex mt-2 gap-2 justify-center'>
                <button className='flex p-2 justify-start items-center rounded border hover:bg-sky-200 duration-200'>
                  <ion-icon name="logo-facebook" style={{fontSize: '25px', color:'#4267B2'}}></ion-icon>
                </button>
                <button className='flex justify-center items-center p-2 rounded border hover:bg-sky-200 duration-200'>
                  <ion-icon name="logo-twitter" style={{fontSize: '25px', color:'#1DA1F2'}}></ion-icon>
                </button>
                <button className='flex p-2 justify-start items-center rounded border hover:bg-sky-200 duration-200'>
                  <ion-icon name="logo-linkedin" style={{fontSize: '25px', color: '#0077b5'}}></ion-icon>
                </button>
                <button className='flex p-2 justify-start items-center rounded border group overflow-hidden w-[41px] hover:w-full transition-all duration-500 ease-in-out'>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="25px" height="25px"><path fill="#e0e0e0" d="M5.5,40.5h37c1.933,0,3.5-1.567,3.5-3.5V11c0-1.933-1.567-3.5-3.5-3.5h-37C3.567,7.5,2,9.067,2,11v26C2,38.933,3.567,40.5,5.5,40.5z"/><path fill="#d9d9d9" d="M26,40.5h16.5c1.933,0,3.5-1.567,3.5-3.5V11c0-1.933-1.567-3.5-3.5-3.5h-37C3.567,7.5,2,9.067,2,11L26,40.5z"/><path fill="#eee" d="M6.745,40.5H42.5c1.933,0,3.5-1.567,3.5-3.5V11.5L6.745,40.5z"/><path fill="#e0e0e0" d="M25.745,40.5H42.5c1.933,0,3.5-1.567,3.5-3.5V11.5L18.771,31.616L25.745,40.5z"/><path fill="#ca3737" d="M42.5,9.5h-37C3.567,9.5,2,9.067,2,11v26c0,1.933,1.567,3.5,3.5,3.5H7V12h34v28.5h1.5c1.933,0,3.5-1.567,3.5-3.5V11C46,9.067,44.433,9.5,42.5,9.5z"/><path fill="#f5f5f5" d="M42.5,7.5H24H5.5C3.567,7.5,2,9.036,2,11c0,1.206,1.518,2.258,1.518,2.258L24,27.756l20.482-14.497c0,0,1.518-1.053,1.518-2.258C46,9.036,44.433,7.5,42.5,7.5z"/><path fill="#e84f4b" d="M43.246,7.582L24,21L4.754,7.582C3.18,7.919,2,9.297,2,11c0,1.206,1.518,2.258,1.518,2.258L24,27.756l20.482-14.497c0,0,1.518-1.053,1.518-2.258C46,9.297,44.82,7.919,43.246,7.582z"/></svg>
                  </div>
                  <a href={`mailto:${data.teacherProfile.email}?Subject=ABC&Body=Body`} className='ml-2 group-hover:block text-sm'>
                    {data.teacherProfile.email}
                  </a>
                </button>
              </div>
            </div>
            <div className='card w-2/3'>
              <div className='text-xl font-medium'>Миний тухай</div>
              <div className='mt-4' dangerouslySetInnerHTML={{__html: data.teacherProfile.aboutMe}}/>
            </div>
          </div>

          {/* ----------------- COURSES ---------------- */}
          
          <div className='my-5'>
            <div className='text-xl my-2'>СУРГАЛТУУД</div>
            {
              data.courseData.map((course, index) => (
                <div className='course-list-card' key={index}>
                  <div className='xs:w-full md:w-1/4 flex relative cursor-pointer' onClick={() => navigate(`${course.urlId}`)}>
                    <img src={`${process.env.REACT_APP_ASSET_URL}${course.image}`} width='100%' height='100%' className='object-cover object-center'/>
                    <img className='absolute top-0 bottom-0 left-0 right-0 m-auto' src={playIcon} width={80}/>
                  </div>
                  <div className='sm:w-3/4 flex md:flex-row xs:flex-col'>
                    <div className='md:w-2/3 xs:w-full p-4 flex flex-col justify-between'>                          
                      <div>
                        <Link to={`/courses/${course.urlId}`} className='card-title line-clamp-2' >{course.title}</Link>
                        <div className='text-gray-500 my-3 text-sm line-clamp-4'>{course.about}</div>
                      </div>
                      {/* <div className='text-sm'>
                        {course.topicList.map((item, i) => (
                          <span className='bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 mb-2 dark:bg-gray-700 dark:text-gray-300' key={i}>{item}</span>
                        ))}
                      </div> */}
                    </div>
                    <div className='md:w-1/3 xs:w-full px-6 sm:my-4 border-l flex flex-col justify-center text-xs'>
                        <Link to={`/courses/${course.urlId}`}>
                          <button className='w-full bg-success text-white px-8 py-2 rounded hover:opacity-90 duration-150 my-1'>
                            ХУДАЛДАЖ АВАХ
                          </button>
                        </Link>
                        <Link to={`/courses/${course.urlId}`}>
                          <button className='w-full bg-sky-500 text-white px-8 py-2 rounded hover:opacity-90 duration-150 my-1'>
                            ҮНЭГҮЙ ҮЗЭХ
                          </button>
                        </Link>
                      <div className='flex text-sm my-1'>
                        <GenerateStar rating={course.avg_rating} color='gold' size={16}/>
                        <span className='ml-2 text-gray-400 font-bold'>
                          {parseFloat(course.avg_rating).toFixed(1)} <span className=' font-normal text-black dark:text-white'>(үнэлгээ)</span>
                        </span>
                      </div>
                      <div className='flex items-center text-sm my-1'>
                        <IoPeople name="people" className='text-gray-400'></IoPeople>
                        <div className='ml-2 '>63 сурагчид</div>
                      </div>
                      <div className='text-sm my-1'>Үнэ: {course.price} ₮</div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </>
      }
    </div>
  )
}

export default TeacherProfile