import React, { useReducer } from 'react';
import ls from './ls';
import toast, { Toaster } from 'react-hot-toast';
const SystemContext = React.createContext()

const initialState = {
  theme: null,
  isLoading: true,
  isSignout: false,
  userToken: null,
  userInfo: null,
  showLoginModal: false,
  showPrivacyPolicy: false,
  showPaymentModal: false,
  showSidebar: false,
  conflictModal: false,
  conflictErrorData: null
};

const reducer = (prevState, action) => {
  switch (action.type) {
    case 'CHANGE_THEME':
      return {
        ...prevState,
        theme: action.status
      };
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        userToken: action.token,
        userInfo: action.info,
        isLoading: false,
        theme: action.theme,
      };
    case 'SIGN_IN':
      return {
        ...prevState,
        isSignout: false,
        userToken: action.token,
        userInfo: action.info,
      };
      case 'SIGN_OUT':
        return {
          ...prevState,
          isSignout: true,
          userToken: null,
          policyCheck: null
        };
      case 'CHANGE_INFO':
        return {
          ...prevState,
          userInfo: action.info,
        };
    case 'LOGIN_MODAL_TOGGLE':
      return {
        ...prevState,
        showLoginModal: action.status
      };
    case 'SHOW_PRIVACY_POLICY':
      return {
        ...prevState,
        showPrivacyPolicy: action.status
      };
    case 'SHOW_PAYMENT_MODAL':
      return {
        ...prevState,
        showPaymentModal: action.status
      };
    case 'SIDEBAR_TOGGLE':
      return {
        ...prevState,
        showSidebar: action.status
      };    
    case 'CONFLICT_MODAL_TOGGLE':
      return {
        ...prevState,
        conflictModal: action.status,
        conflictErrorData: action.data
      }
  }
  return prevState;
};

const SystemProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  React.useLayoutEffect(() => {
    const bootstrapAsync = async () => {
      let userToken;
      let userInfo;
      let userTheme;
      try {
        userToken = ls.get('userToken');
        userInfo = ls.get('userInfo')
        userTheme = localStorage.getItem('theme')
        dispatch({ type: 'RESTORE_TOKEN', token: userToken,  info: userInfo, theme: userTheme});
      } catch (e) {
        console.log(e);
      }
    };

    bootstrapAsync();
  }, []);


  const actions = React.useMemo(
    () => ({
      signIn: async (token, info) => {
        ls.set('userToken', token)
        ls.set('userInfo', {...info})
        dispatch({ type: 'SIGN_IN', token: token, info: info });
      },
      changeProfileInfo: (info) => {
        ls.set('userInfo', {...info})
        dispatch({ type: 'CHANGE_INFO', info: info });
      },
      signOut: async () => {
        await ls.remove('userToken')
        await dispatch({ type: 'SIGN_OUT' })
        await window.location.reload(false)
      },
      onSuccess: (info) => {
        toast.success(info)
      },
      onError: (info) => {
        toast.error(info)        
      },
      onNotConnect: () => {
      },
      unauthorized: () => {
        dispatch({type: 'LOGIN_MODAL_TOGGLE', status: true})
      },
      changeTheme: (theme) => {
        localStorage.setItem('theme', theme)
        dispatch({ type: 'CHANGE_THEME', status: theme})
      },
      handleLoginModalToggle: (isShow) => {
        dispatch({type: 'LOGIN_MODAL_TOGGLE', status: isShow})
      },
      handleShowPrivacyPolicy: (status) => {
        dispatch({type: 'SHOW_PRIVACY_POLICY',status: status})
      },
      handlePaymentModal: (status) => {
        dispatch({type: 'SHOW_PAYMENT_MODAL',status: status})
      },
      handleSidebar: (status) => {
        dispatch({type: 'SIDEBAR_TOGGLE',status: status})
      },
      handleConflictModal: (status, data) => {
        dispatch({type: 'CONFLICT_MODAL_TOGGLE', status: status, data})
      },
    }),
    []
  );

  return (
    <SystemContext.Provider value={{state: state, action: actions}}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          duration: 3000,
          style: {
            fontWeight:'600'
          },
        }}
      />
      {props.children}
    </SystemContext.Provider>
  )
}

export {
  SystemContext,
  SystemProvider
}
