import axios from 'axios'
import React, { useState } from 'react'

function CertificateSearch() {
  const [ searchValue, setSearchValue ] = useState(null)
  const [ certifcate, setCertificate ] = useState(null)

  const getData = () => {
    axios({
      method: 'GET',
      url: `home/${searchValue}`
    }).then((res) => {
      setCertificate(res.data.certifcate)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    getData()
  }

  return (
    <div className='max-w-7xl mx-auto px-3'>
      <div className='py-6'>
        <div className='card p-4'>
          <div className='flex justify-between items-center dark:bg-gray-800 dark:text-white'>
            <form className='flex items-center border rounded dark:border-slate-700 py-2 px-3' onSubmit={handleSubmit}>
              <input 
                type={'search'} 
                placeholder='Гэрчилгээний дугаар оруулах...' 
                className='focus:outline-none dark:bg-gray-800 w-[300px]'
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button type='submit' className='flex'>
                <ion-icon name="search-outline" style={{fontSize: '22px', color:'#7d7e7f'}}></ion-icon>
              </button>
            </form>
          </div>
          <div className='mt-3'>
            {
              certifcate &&
              <iframe style={{ height:'100vh'}} width='90%' src={`${process.env.REACT_APP_ASSET_URL}${certifcate}#toolbar=0&view=fitH`}/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CertificateSearch