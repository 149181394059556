import React, { useContext } from 'react'
import { SystemContext } from 'utils/context';

function Modal({show, toggleShow, title, children, className}) {

  if(!show){
    return null;
  }

  const handleCloseModal = () => {
    toggleShow(!show)
  }

  return (
    <div className={`fixed top-0 h-full right-0 w-full bg-black/50  flex justify-center items-center z-50`}>
      <div className='animate-fade-down'>
        <div className={`flex flex-col relative bg-white rounded-md text-black max-h-[40rem] dark:bg-gray-800 dark:text-white ${className && className}`}>
          <button className='absolute flex top-0 right-0 m-3 text-gray-400 hover:text-sky-400 duration-200 rounded-full' type='button' onClick={() => handleCloseModal()}>
            <ion-icon name="close-circle-outline" size='large' ></ion-icon>
          </button>
          <div className={`h-[52px] px-6 py-3 font-medium text-lg text-slate-500 dark:text-slate-300 border-b`}>{title && title}</div>
          <div className='py-2 px-5'>
            {children}
          </div>  
        </div>
      </div>
    </div>
  )
}

export default Modal