import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import toast from 'react-hot-toast';

function Test({testData, handleSubmit}) {
  const [ data, setData ] = useState([])
  const [isLast, setIsLast] = useState(false)
  const [ currentTestAnswer, setCurrentTestAnswer ] = useState(null)
  const [ testAnswers, setTestAnswers ] = useState([])
  const [ testResult, setTestResult ] = useState(0)
  const [ resultPercent, setResultPercent ] = useState(0)
  const [ isFinished, setIsFinished ] = useState(false)
  const [ resultList, setResultList ] = useState([])

  useEffect(() => {
    let fixData = []
    if(testData){
      testData.map((item, i) => {
        item.course_test_answers.map((answer, i) => {
          answer.isChecked = false
        })
        item.isFilled = false;
        item.isCorrect = false;
        fixData.push({...item})
      })
      setData(fixData)
    }
  },[testData])

  const handleChangeAnswer = (test, testIndex, answerIndex) => {
    let fixedData = [...data];
    if(test.testType == 'Нэг сонголттой'){
      fixedData[testIndex].course_test_answers[answerIndex].isChecked = true;
      fixedData[testIndex].isFilled = true
      if(fixedData[testIndex].course_test_answers[answerIndex].trueAnswer == 1){
        fixedData[testIndex].isCorrect = true
      }
      else{
        fixedData[testIndex].isCorrect = false
      }
    }
    else{
      if(!fixedData[testIndex].course_test_answers[answerIndex].isChecked){
        fixedData[testIndex].course_test_answers[answerIndex].isChecked = !fixedData[testIndex].course_test_answers[answerIndex].isChecked;
        fixedData[testIndex].isFilled = true
      }
      else{
        fixedData[testIndex].course_test_answers[answerIndex].isChecked = !fixedData[testIndex].course_test_answers[answerIndex].isChecked;
        let isFilled = fixedData[testIndex].course_test_answers.filter((answer) => answer.isChecked); 
        if(isFilled.lenght == 0){
          fixedData[testIndex].isFilled = false
        }   
      }
    }
    setData(fixedData)
  }

  const handleFinish = () => {
    let notFilled = []
    let correctAnswers = 0
    data.map((item, i) => {
      if(!item.isFilled){
        notFilled.push(i+1)
      }
      if(item.isCorrect){
        correctAnswers += 1
      }
    })
    if(notFilled.length > 0){
      toast.error(`${notFilled.length} тест бөглөөгүй байна !`)
    }
    else{
      setIsFinished(true)
      setResultPercent(parseInt((correctAnswers) * 100 / testData.length))
      handleSubmit(parseInt((correctAnswers) * 100 / testData.length))
    }
  }
  return (
    <div>
      <div className={`swiper-pagination`}></div>
      <button 
        id='next' 
        className={`absolute z-10 right-5 bottom-[15px] py-1 px-2 bg-sky-500 text-white rounded hover:bg-sky-400 text-sm`}
        onClick={() => setTestAnswers([...testAnswers, {...currentTestAnswer}])}
      >
        дараагийн
      </button>
      <button 
        id='prev' 
        className={`absolute z-10 left-5 bottom-[15px] py-1 px-2 bg-sky-500 text-white rounded hover:bg-sky-400 text-sm`}
        onClick={() => setTestAnswers(testAnswers.splice(-1))}
      >
        өмнөх
      </button>
      {
        isLast && !isFinished &&
        <button 
          className={`absolute z-10 right-5 bottom-[15px] py-1 px-2 bg-green-500 text-white rounded hover:bg-green-400 text-sm`} 
          onClick={handleFinish}
        >
          Дуусгах
        </button>
      }
      {/* { isFinished &&
        <button 
          className={`absolute z-10 right-5 bottom-[15px] py-1 px-2 bg-green-500 text-white rounded hover:bg-green-400 text-sm`}
          onClick={() => {setIsFinished(false); setIsLast(false)}}
        >
          Буцах
        </button>
      } */}
      <div className='relative'>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          centeredSlides
          followFinger={false}
          className='mb-[50px]'
          allowTouchMove={false}  
          onSlideChange={(e) => e.isEnd ? setIsLast(true) : setIsLast(false)}   
          navigation={{
            nextEl: '#next',
            prevEl: '#prev',
            disabledClass: 'swiper-button-disabled hidden'
          }}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: (index, className) => {
              return '<span class="' + className + '">' + ([index+1]) + '</span>';
            },
          }}
          modules={[Pagination, Navigation]}
        >
          { !isFinished &&
            data.map((item, i) => (
              <SwiperSlide key={i} >
                <div className={`flex flex-col px-2 items-center gap-3`}>
                  <div dangerouslySetInnerHTML={{__html: item.question}}></div>
                  <div className='flex flex-col gap-2'>
                    {
                      item.course_test_answers.map((answer, idx) => (
                        <div key={idx} className='flex items-center'>
                          <input 
                            id={answer.id} 
                            name={item.id}
                            value={answer.id} 
                            checked={answer.isChecked}
                            type={item.testType == 'Нэг сонголттой' ? 'radio' : 'checkbox'} 
                            onChange={(e) => handleChangeAnswer(item, i, idx)}
                            className='mr-2' 
                          />
                          <label htmlFor={answer.id}>{answer.answer}</label>     
                        </div>
                      ))
                    }
                  </div>
                </div>            
              </SwiperSlide>
            ))
          }
          {
            isFinished && 
            <div className='flex flex-col justify-center items-center'>
              <div className=' text-8xl text-green-500 font-bold'>
                {resultPercent}%
              </div>
              <div className='flex gap-2'>
                {data.map((item, i) => (
                  <div style={{color: item.isCorrect ? 'green' : 'red'}}>{i+1}</div>
                ))}
              </div>
            </div>
          }
        </Swiper>
      </div>
    </div>
  )
}


export default Test