import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { CgClose } from 'react-icons/cg'
import FormError from '../formError'
import * as Yup from 'yup'

function ForgetPassword({handleForm, handleSubmit, logo, handleModal}) {

  const validSchema = Yup.object().shape({
    email: Yup.string().email('Зөв имайл оруулна уу').max(255).required('Имэйл хаягаа оруулна уу'),
  });

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validSchema}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({
        handleChange,
        isSubmitting,
        handleSubmit
      }) => (
        <Form action="#" className="flex flex-col items-center relative w-[25rem] bg-white py-4 px-6 rounded-md text-black dark:bg-gray-800">
            <button className='absolute top-0 right-0 p-3' type='button' onClick={() => handleModal(false)}>
              <CgClose size={25} className='text-sky-400'/>
            </button>
            <a href="index.html" className="sign__logo">
              <img src={logo} alt=""/>
            </a>
            <div className='text-sky-700 font-medium'>НУУЦ ҮГ СЭРГЭЭХ</div>
            <div className="my-3 w-full">
              <input 
                name='email'
                type='text'
                className="input-field" 
                placeholder="И-Мэйл хаяг"
                onChange={handleChange}
              />
              <ErrorMessage name='email' render={FormError}/>
            </div>           
            <div className='p-2 w-full rounded mb-3 text-sm text-slate-600 bg-orange-200 dark:bg-amber-400 dark:text-gray-700'>
              Та бүртгэлтэй и-мэйл хаягаа оруулж илгээх товч дарснаар таны и-мэйл хаягт шинэ нууц үг илгээгдэнэ. Та тухайн шинэ нууц үгээрээ нэвтрэх ороорой 
            </div>
            <button className="text-white bg-sky-400 hover:bg-sky-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 duration-150" type='submit'>ИЛГЭЭХ</button>
            <div className="text-gray-500 text-center my-4">
              Нэвтрэх хэсэг рүү 
              <button className='ml-2 text-sky-500' onClick={() => handleForm(false)}>Буцах</button>
            </div>    
        </Form>
      )}
    </Formik>
  )
}

export default ForgetPassword
