import React from 'react'

function ConfirmModal({show, showToggle, title, className,handleOk, handleCancel, okText='Зөвшөөрөх', cancelText='Цуцлах', okBtnClass, cancelBtnClass, closeable, closeBtnHide=false}) {
  
  if(!show){
    return null;
  }

  const handleCloseModal = () => {
    showToggle(!show)
  }

  return (
    <>
    <div className={`fixed top-0 h-full right-0 w-full bg-black/50 z-50 flex justify-center items-center`}>
      <div className='animate-fade-down'>
        <div className={`relative bg-white rounded-lg shadow dark:bg-gray-700 ${className}`}>
            <div className="flex justify-end p-2">
              {
                closeable && 
                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" onClick={() => handleCloseModal()}>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
                </button>
              }
            </div>
          <div className="p-6 pt-0 text-center">
            <svg className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{title}</h3>
            <button 
              type="button" 
              className={`font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 ${okBtnClass ? okBtnClass : 'text-white bg-red-600 hover:bg-red-800'}`} 
              onClick={handleOk}
            >
              {okText}
            </button>
            {
              !closeBtnHide &&
              <button type="button" 
                className={`rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 ${cancelBtnClass ? cancelBtnClass : 'text-gray-500 bg-white hover:bg-gray-100'}`} 
                onClick={handleCancel}
              >
                {cancelText}
              </button>
            }
          </div>
        </div>
      </div>
    </div>    
    </>
  )
}

export default ConfirmModal