import moment from 'moment';
import React, { useContext, useState } from 'react'
import ReactDOM from 'react-dom';
import { SystemContext } from '../../utils/context'

function ConflictModal({show}) {
  const [ data, setData ] = useState(null)
  const [ checked, setChecked ] = useState(false);

  const systemContext = useContext(SystemContext)

  if(!show){
    return null;
  }

  const handleCloseModal = () => {
    systemContext.action.handleConflictModal(false, null)
  }

  const handleLoginBtn = () => {
    systemContext.action.handleConflictModal(false, null)
    systemContext.action.handleLoginModalToggle(true)
  }

  return ReactDOM.createPortal(
    <div className="fixed top-0 h-full right-0 w-full bg-black/50 z-30 flex justify-center items-center">
      <div className='animate-fade-down'>
        <div className='flex flex-col items-center relative w-[40rem] rounded-md text-black max-h-[40rem] px-6 py-3 bg-orange-100'>
          <div className='  text-slate-500'>
            <button className='absolute top-0 right-0 p-3 hover:text-sky-500' type='button' onClick={() => handleCloseModal()}>
              <ion-icon name="close-circle-outline" size='large' style={{color: 'gray'}}></ion-icon>
            </button>
          </div>
          <div className='px-6 py-3 text-slate-600'>
            <div className='mb-4 text-xl text-red-400'>
              Таны хаягаар давхар нэвтэрсэн байна. 
            </div>
            <div className='text-sm text-slate-700'>
              <table>
                <thead className='bg-orange-200 text-slate-500 font-medium'>
                  <tr>
                    <td>IP</td>
                    <td className='w-[100px]'>Веб хөтөч</td>
                    <td>Хаанаас</td>
                    <td className='w-[100px]'>Төхөөрөмж</td>
                    <td>Хэзээ</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='pr-2'>{systemContext.state.conflictErrorData.ip}</td>
                    <td className='pr-2'>{systemContext.state.conflictErrorData.browser}</td>
                    <td className='pr-2'>{systemContext.state.conflictErrorData.city}, {systemContext.state.conflictErrorData.country}</td>
                    <td className='pr-2'>{systemContext.state.conflictErrorData.device}</td>
                    <td className='pr-2'>{moment(systemContext.state.conflictErrorData.date).format('YYYY/MM/DD') }</td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </div>
          <button 
            className='bg-sky-400 text-white px-3 py-1 disabled:bg-gray-300 disabled:cursor-not-allowed mt-4 rounded' 
            onClick={handleLoginBtn}
          >
            Дахин нэвтрэх
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("conflict-modal")
  )
}

export default ConflictModal
