import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

function ViewCertificate() {
  const [ certifcate, setCertificate ] = useState(null)
  const { certNumber } = useParams()

  useEffect(() => {
    if(certNumber){
      getData()
    }
  },[])

  const getData = () => {
    axios({
      method: 'GET',
      url: `home/${certNumber}`
    }).then((res) => {
      setCertificate(res.data.certifcate)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div className='max-w-7xl mx-auto px-3'>
      <div className='py-6'>
        <div className='card p-4'>
          <div className='mt-3'>
            {
              certifcate &&
              <iframe style={{ height:'100vh', margin: 'auto'}} width='90%' src={`${process.env.REACT_APP_ASSET_URL}${certifcate}#toolbar=0&view=fitH`}/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewCertificate