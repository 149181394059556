import React, { useEffect, useRef, useState } from 'react'
import Hls from "hls.js";
import Plyr, { APITypes, PlyrProps, PlyrInstance } from 'plyr-react'

function Player({src, poster}) {
  const ref = useRef(null)
  var hls = new Hls();
  
  
  
  useEffect(() => {
    const loadVideo = async () => {
      const video = document.getElementById("plyr")
      var hls = new Hls();
      hls.loadSource(`${src}`);
      hls.attachMedia(video);
      // ref.current.plyr.media = video;
      hls.on(Hls.Events.MANIFEST_PARSED, function (e) {
        let newQuality = []
        hls.levels.map((l) => {
          newQuality.push(l.height)
        })
      });
    };
    loadVideo();
  });  

  const updateQuality = (newQuality) => {
    hls.levels.forEach((level, levelIndex) => {
      if (level.height === newQuality) {
        hls.currentLevel = levelIndex;
      }
    });
  }
  return (
    <div>
      <Plyr
        id='plyr'
        source={{}}
        ref={ref}  
        type='application/x-mpegURL'
        poster={poster && poster}
        playsInline
        options={{
          seekTime: 5,
          settings: ['quality', 'speed'],
          quality: {
            default: 480,
            options: [720, 480],
            forced: true,
            onChange: (e) => updateQuality(e)
          },
          captions: {active: true, language: 'auto'},
          i18n: {
            speed: 'Хурд',
            quality: 'Чанар',
            normal: 'Хэвийн'
          },
        }}  
      />
    </div>
  )
}

export default Player