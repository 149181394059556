import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup';
import axios from 'axios'
import { SystemContext } from '../../utils/context'
import FormError from '../formError';
import Signup from './signup'
import { CgClose } from 'react-icons/cg'
import logo from '../../assets/isa_logo.png'
import './style.css'
import ForgetPassword from './forgetPassword';
import { useNavigate, useLocation } from 'react-router-dom';

function LoginModal({show}) {
  const [ signup, setSignup ] = useState(false);
  const [ forgetPassword, setForgetPassword ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false)
  const systemContext = useContext(SystemContext)
  const navigate = useNavigate()

  useEffect(() => {
    // if(show){
    //   document.body.style.overflow = "hidden"
    // }
    // else{
    //   document.body.style.overflow = 'auto'
    // }
  },[show])

  if(!show){
    return null;
  }

  const handleSigninSubmit = (values) => {
    axios({
      method: 'POST',
      url: 'login',
      data: {
        ...values
      }
    }).then(async (res) => {
      await systemContext.action.signIn(res.data.token, res.data.profile)
      await systemContext.action.handleLoginModalToggle(false)
      if(res.data.profile.policyCheck == 0){
        setTimeout(() => {
          systemContext.action.handleShowPrivacyPolicy(true)
        },[1000])
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleSignupSubmit = (values) => {
    axios({
      method: 'POST',
      url: 'signup',
      data: {
        email: values.email,
        password: values.password,
        policyCheck: values.policyCheck ? 1: 0
      }
    }).then((res) => {
      setSignup(false)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleResetPasswordSubmit = (values) => {
    axios({
      method: 'POST',
      url: 'resetpassword',
      data: {
        email: values.email,
      }
    }).then((res) => {
      setForgetPassword(false)
    }).catch((err) => {
      console.log(err)
    })
  }
  
  const validSchema = Yup.object().shape({
    email: Yup.string().email('Зөв имайл оруулна уу').max(255).required('Имэйл хаягаа оруулна уу'),
    password: Yup.string().max(255).required('Нууц үгээ оруулна уу')
  });  

  const handleModal = (status) => {
    systemContext.action.handleLoginModalToggle(status)
  }

  const handleCloseModal = () => {
    handleModal(false)
  }

  return ReactDOM.createPortal(
    <div className="fixed top-0 h-full right-0 w-full bg-black/50 z-30 flex justify-center items-center">
      <div className='animate-fade'>
        { signup && <Signup handleForm={setSignup} logo={logo} handleSubmit={handleSignupSubmit} handleModal={handleModal}/>}
        { forgetPassword && <ForgetPassword handleForm={setForgetPassword} logo={logo} handleSubmit={handleResetPasswordSubmit} handleModal={handleModal}/>}
        { !signup && !forgetPassword && 
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validSchema}
            onSubmit={handleSigninSubmit}
          >
              {({
                handleChange,
                isSubmitting,
                values
              }) => (
                <Form action='#' className='flex flex-col items-center relative w-[25rem] bg-white py-4 px-6 rounded-md text-black dark:bg-gray-800'>
                  <button className='absolute top-0 right-0 p-3' type='button' onClick={() => handleCloseModal()}>
                    <CgClose size={25} className='text-sky-400'/>
                  </button>
                  <div className="flex justify-center items-center mt-2" style={{marginBottom: '20px'}} >
                    <img src={logo} alt="logo" width={250}/>
                  </div>
                  <div className='text-sky-700 font-medium'>ТАВТАЙ МОРИЛ</div>
                  <div className="mt-3 w-full">
                    <input 
                      name='email' 
                      type="text" 
                      className="input-field" 
                      placeholder="И-Мэйл хаяг" 
                      onChange={handleChange}
                    />
                    <ErrorMessage name="email" render={FormError}/>
                  </div>
                  <div className="my-3 w-full flex relative">
                    <input 
                      name='password'
                      type={showPassword ? 'text' : 'password'} 
                      className="input-field pr-9" 
                      placeholder="Нууц үг" 
                      onChange={handleChange}
                    />
                    {
                      values.password &&
                      <button type='button' className='flex justify-center items-center absolute right-0 top-0 bottom-0 m-2 p-1.5 hover:bg-slate-200 rounded-full' onClick={() => setShowPassword(!showPassword)}>
                        <ion-icon name={`eye-${showPassword ? '' : 'off-'}outline`}></ion-icon>
                      </button>
                    }
                  </div>
                  {/* <div className="sign__group sign__group--checkbox">
                    <input id="remember" name="remember" type="checkbox" checked="checked"/>
                    <label for="remember">Намайг сана</label>
                  </div> */}
                  <button type="submit" class="text-white bg-sky-400 hover:bg-sky-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 duration-150">НЭВТРЭХ</button>
                  <div className="text-gray-500 text-center my-4">
                    Хэрвээ та бүртгэлгүй бол? 
                    <button className='ml-2 text-sky-500' type='button' onClick={() => setSignup(true)}>
                      Бүртгүүлэх!
                    </button>
                    <button type='button' className='text-sky-500' onClick={() => setForgetPassword(true)}>Нууц үгээ мартсан?</button>
                  </div>  
                </Form>                  
              )}
          </Formik>
        }
      </div>
    </div>,
    document.getElementById("login-modal")
  )
}

export default LoginModal
